export default {
  decodeToken(token) {
    if (token) {
      const tokenBody = token.split('.')[1]
      return JSON.parse(atob(tokenBody))
    }
    return {}
  },
  getToken(tokenName) {
    // Returns a decoded token object for the specified token, or
    try {
      let token = window.sessionStorage.getItem(tokenName)
      return this.decodeToken(token)
    } catch (e) {
      return null
    }
  }
}
