<template>
  <v-app :class="{ mobile: $vuetify.breakpoint.xsOnly }">
    <AppBar
      color="primary"
      app-name="oes-online-enrolment"
      title="Online Enrolment"
      :search-filter-mutator="true"
      :show-nav-icon="false"
      data-testid="app-bar"
      :extended="$vuetify.breakpoint.xsOnly"
      :enable-home-link="false"
    >
      <template #appIcon>
        <AppIcon
          app-name="my-nsw-gov"
          :transparent="true"
          size="50px"
          class="mt-2 ml-7"
        />
      </template>

      <template #dataSwitcher v-if="$vuetify.breakpoint.smAndUp">
        <school-switcher
          :schools="schoolsForUser"
          :value="currentSchool"
          @input="switchSchool"
        />
      </template>

      <template #myEssentials v-if="selectedSchool !== null">
        <AppSettingsModal :school="selectedSchool" />
      </template>

      <template #profile>
        <Profile
          :given-name="user.givenName"
          :surname="user.sn"
          :update-profile="false"
          :change-password="false"
          :update-secret-question="false"
          :portal-settings="false"
          :logout="true"
          :logout-handler="logout"
        />
      </template>
      <template #extension v-if="$vuetify.breakpoint.xsOnly">
        <school-switcher
          :schools="schoolsForUser"
          :value="currentSchool"
          @input="switchSchool"
        />
      </template>
    </AppBar>
    <SessionCountdown
      :show-countdown="showBanner"
      :minutes-warning="minutesTimeoutWarning"
      session-timeout-dialog-css-class="session-timeout"
      session-countdown-alert-css-class="session-countdown"
      @continue="resume"
      @login="login"
      @expired="clearTokens"
    />
    <v-main data-testid="content">
      <v-container fluid>
        <v-fade-transition mode="out-in">
          <keep-alive include="Certifications">
            <router-view :key="$route.fullPath">
              <template #inline-alert>
                <IeWarning />
              </template>
            </router-view>
          </keep-alive>
        </v-fade-transition>
      </v-container>
    </v-main>
    <AppSpinner />
    <EnvironmentInfo
      :version-no="versionNo"
      :environment="environment"
      :is-visible="isVisible"
      @hide="hide"
    />
    <!-- app wide components -->
    <BlockUI v-if="$store.getters.isLoading" />
    <AccessDenyModal
      v-model="accessDeny"
      max-width="550px"
      @logout="logout"
      persistent
    />
    <Notifications :items="notifications" />
    <ErrorModal v-model="showErrorModal" @retry="retry" :error="error" />
    <UserErrorModal
      v-model="showUserErrorModal"
      @retry="handleUserError"
      :error="error"
    />
  </v-app>
</template>

<script>
import {
  AppBar,
  NavigationDrawer,
  Profile,
  MyEssentials,
  AppIcon,
  BlockUI,
  SessionCountdown,
  EnvironmentInfo
} from '@nswdoe/doe-ui-core'
import AccessDenyModal from '@/views/AccessDenyModal'
import { mapGetters, mapActions, mapMutations } from 'vuex'

import SchoolSwitcher from '@/components/SchoolSwitcher'
import Notifications from '@/components/Notifications'
import ErrorModal from '@/components/ErrorModal'
import UserErrorModal from '@/components/UserErrorModal'
import IeWarning from '@/components/IeWarning'
import AppSettingsModal from '@/components/AppSettingsModal'
import AppSpinner from '@/components/AppSpinner.vue'

import { API_ERROR_SUBTYPES, NODE_ENVIRONMENTS } from '@/constants'

const App = {
  name: 'App',
  components: {
    BlockUI,
    AppBar,
    NavigationDrawer,
    Profile,
    MyEssentials,
    AppIcon,
    AccessDenyModal,
    Notifications,
    ErrorModal,
    UserErrorModal,
    SchoolSwitcher,
    IeWarning,
    SessionCountdown,
    EnvironmentInfo,
    AppSettingsModal,
    AppSpinner
  },
  computed: {
    ...mapGetters([
      'idToken',
      'user',
      'schoolsForUser',
      'accessDeny',
      'currentSchool',
      'notifications',
      'error',
      'hasApiError',
      'hasUserError',
      'timeoutTimestamp',
      'currentSchool'
    ]),
    showBanner: {
      get() {
        return this.$store.state.auth.showInactivityBanner
      },
      set(value) {
        this.setShowInactivityBanner(value)
      }
    },
    environment() {
      if (this.envColour && this.envName) {
        return `${this.envColour}-${this.envName.toLowerCase()}`
      }
      if (this.envName) {
        return this.envName.toLowerCase()
      }
      return null
    },
    selectedSchool() {
      return this.currentSchool
    }
  },
  beforeMount() {
    this.$activityMonitor.activate()
  },
  created() {
    this.$store.dispatch('setCurrentUser')
  },
  data() {
    return {
      showErrorModal: false,
      showUserErrorModal: false,
      minutesTimeoutWarning: parseInt(
        process.env.VUE_APP_ACTIVITY_TIMEOUT_WARNING_MINUTES
      ),
      isVisible:
        process.env.VUE_APP_ENV_NAME &&
        process.env.VUE_APP_ENV_NAME !== NODE_ENVIRONMENTS.PROD,
      versionNo: process.env.VUE_APP_VERSION,
      envColour: process.env.VUE_APP_ENV_COLOUR,
      envName: process.env.VUE_APP_ENV_NAME
    }
  },
  watch: {
    hasApiError: function () {
      this.showErrorModal = this.hasApiError && !this.accessDeny
    },
    hasUserError: function () {
      this.showUserErrorModal = this.hasUserError
    }
  },
  methods: {
    logout() {
      this.$router.push('logout')
    },
    handleUserError() {
      this.$router.push('/')
    },
    retry() {
      switch (this.error.fallbackCode) {
        case API_ERROR_SUBTYPES.saveCertificationDetails:
          this.$store.commit('resetError')
          this.$store.dispatch('updateCertification')
          return
        case API_ERROR_SUBTYPES.acceptCertification:
          this.$store.commit('resetError')
          this.$store.dispatch('acceptCertification')
          return
        case API_ERROR_SUBTYPES.declineCertification:
          this.$store.commit('resetError')
          this.$store.dispatch('declineCertification')
          return
        default:
          this.$store.commit('resetError')
          this.$router.go()
      }
    },
    ...mapActions([
      'setCurrentSchool',
      'setCurrentUser',
      'fetchCertifications',
      'clearTokens',
      'authenticateUser'
    ]),
    ...mapMutations(['setShowInactivityBanner']),
    switchSchool(schoolCode) {
      if (!schoolCode) {
        return
      }
      this.setCurrentSchool(schoolCode)
      this.fetchCertifications(schoolCode)
      if (this.$route.name !== 'Certifications') {
        this.$router.push('/')
      }
    },
    resume() {
      this.showBanner = false
      this.$activityMonitor.activate()
    },
    async login() {
      this.showBanner = false
      await this.authenticateUser()
    },
    hide() {
      this.isVisible = false
    }
  }
}

export default App
</script>

<style lang="scss">
@import './scss/ads.scss';
@import '@/scss/app.scss';

.mobile {
  .v-toolbar__content {
    display: none;
  }
  .theme--dark.v-app-bar.v-toolbar.v-sheet {
    height: 30px !important;
  }
  .v-main {
    padding-top: 50px !important;
  }
}

.session-countdown {
  margin-top: 6rem;
  margin-bottom: -3rem;
  margin-left: 3rem;
  margin-right: 3rem;
}

.session-timeout {
  top: 4rem;
}
</style>
