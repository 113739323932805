<template>
  <v-btn
    class="toggle-button"
    :class="{
      [toggleOnClass]: internalValue,
      [toggleOffClass]: !internalValue
    }"
    v-bind="$attrs"
    v-on="$listeners"
    @click="toggleValue"
  >
    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    <template v-for="(_, name) in $slots" #[name]>
      <slot :name="name" />
    </template>
  </v-btn>
</template>

<script>
export default {
  name: 'ToggleButton',
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toggleOnClass: {
      type: String,
      default: 'toggle-button--on'
    },
    toggleOffClass: {
      type: String,
      default: 'toggle-button--off'
    }
  },
  data() {
    return {
      dataValue: this.value
    }
  },
  watch: {
    value(newValue) {
      this.dataValue = newValue
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.dataValue
      },
      set(newVal) {
        this.dataValue = newVal
        this.$emit('input', newVal)
      }
    }
  },
  methods: {
    toggleValue() {
      this.internalValue = !this.internalValue
    }
  }
}
</script>
