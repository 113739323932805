<template>
  <span
    data-testid="application-badge"
    class="application-badge"
    :style="{ borderColor: color }"
  >
    <!-- 
      The following aria-hidden attr is used to force NVDA to read out the aria-label
      https://www.powermapper.com/tests/screen-readers/labelling/acronym-abbr-title/
    -->
    <abbr :title="title" :style="[{ color }]" :aria-label="title"
      ><span aria-hidden="true">{{ abbr }}</span></abbr
    >
  </span>
</template>

<script>
import { ADS_Colors } from '@nswdoe/doe-ui-core'
export default {
  props: {
    abbr: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: ADS_Colors.PRIMARY_RED
    }
  }
}
</script>

<style lang="scss">
.application-badge {
  text-transform: uppercase;
  border: 2px solid;
  padding: 0 3px;
  border-radius: 4px;
  font-weight: bold;
  letter-spacing: 1px;
  abbr {
    text-decoration: none;
    word-break: normal;
  }
}
</style>
