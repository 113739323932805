import settingsAPI from 'api/settings'

const settingsModule = {
  state: {
    customisationSetting: null,
    showSpinner: false
  },
  getters: {
    customisationSetting: (state) => state.customisationSetting,
    isSpinner: (state) => state.showSpinner
  },
  mutations: {
    setCustomisationSettings(state, setting) {
      state.customisationSetting = setting
    },
    setShowShowSpinner(state, value) {
      state.showSpinner = value
    }
  },
  actions: {
    showSpinner({ commit }) {
      commit('setShowShowSpinner', true)
    },
    hideSpinner({ commit }) {
      commit('setShowShowSpinner', false)
    },
    clearCustomisationSettings({ commit }) {
      commit('setCustomisationSettings', null)
    },
    async getSavedCustomisationSettings({ commit, dispatch }, schoolCode) {
      dispatch('showSpinner')
      await settingsAPI
        .getPeiCustSettings(schoolCode)
        .then((response) => {
          if (response && response.data) {
            commit('setCustomisationSettings', response.data.body)
          }
        })
        .finally(() => {
          dispatch('hideSpinner')
        })
    },
    async saveCustomisationSettings(_, { schoolCode, settings }) {
      await settingsAPI.savePeiCustSettings(schoolCode, settings)
    }
  }
}

export default settingsModule
