<template>
  <div>
    <Banner class="d-none d-md-flex">
      <template #content>
        <v-container fluid class="ml-10 pr-15">
          <v-row>
            <v-col cols="8" class="d-flex align-start">
              <v-icon size="48px" class="banner__icon mr-3">
                playlist_add_check
              </v-icon>
              <div>
                <h1 class="banner__title">Principal Certification</h1>
                <p class="banner__text">
                  Enrolment applications for {{ currentSchoolName }}
                </p>
              </div>
            </v-col>
            <v-col class="count-container count-container--new" cols="2">
              <v-card
                color="primary lighten-1 d-flex align-center flex-column justify-center pt-8 pb-8"
              >
                <span class="white--text text-h4">
                  {{ newCertificationsCount }}
                </span>
                <span class="white--text text-body-2"> new applications </span>
              </v-card>
            </v-col>
            <v-col class="count-container count-container--ready" cols="2">
              <v-card
                color="primary lighten-1 d-flex align-center flex-column justify-center pt-8 pb-8"
              >
                <span class="white--text text-h4">{{
                  readyCertificationsCount
                }}</span>
                <span class="white--text text-body-2">ready applications</span>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </Banner>
    <v-container
      fluid
      text-left
      class="certifications-container pt-5 ml-10 pr-15"
    >
      <div class="inline-alert__container">
        <slot name="inline-alert" />
      </div>
      <v-row>
        <v-col class="mt-4 pt-0 pl-3">
          <div class="table-controls">
            <div v-if="isMobile" class="table-controls__search-row">
              <v-text-field
                class="table-controls__search"
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                placeholder="Find student"
                hide-details
                solo
                persistent-placeholder
              />
              <ToggleButton
                v-model="showFilterListMobile"
                class="ml-2 table-controls__filter-toggle-button"
                icon
              >
                <v-icon class="table-controls__filter-toggle-icon" size="28">
                  mdi-filter-variant
                </v-icon>
              </ToggleButton>
            </div>
            <TopSlideTransition element-max-height="6rem">
              <div
                v-if="!isMobile || showFilterListMobile"
                class="table-controls__filter-row"
                aria-labelledby="filter-by-label"
              >
                <span
                  v-if="!isMobile"
                  class="filter-label d-none d-md-inline"
                  id="filter-by-label"
                >
                  Filter by:
                </span>
                <span class="pl-3">
                  <ListViewFilter
                    v-model="showFilters.status"
                    label="Status"
                    aria-label="Filter By Status"
                    type="checkbox"
                    :filter-options="statusFilterOptions"
                    :selection.sync="filterSelections.status"
                    v-bind="filterMenuAttrs"
                  />
                </span>
                <span class="pl-3">
                  <ListViewFilter
                    v-model="showFilters.scholasticYear"
                    label="Scholastic year"
                    aria-label="Filter By Scholastic Year"
                    type="checkbox"
                    :filter-options="scholasticYearFilterOptions"
                    :selection.sync="filterSelections.scholasticYear"
                    v-bind="filterMenuAttrs"
                  />
                </span>
              </div>
            </TopSlideTransition>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col id="table-container">
          <v-overlay
            class="filter-overlay"
            :value="isFilterShowing && isMobile"
            absolute
          />
          <AdsDataTable
            data-testid="cert-list"
            :headers="defaultHeaders"
            :items="filteredCertifications"
            class="certification-list"
            :class="{ 'mobile-table': isMobile }"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @click:row="goToCertification"
            @current-items="setCurrentItems"
            search-label="Find Student"
            :search.sync="search"
            :add-header-button-aria-label="true"
            must-sort
            :footer-props="{
              'items-per-page-options': options.rowsPerPageItems
            }"
            :items-per-page="50"
          >
            <!--  fullName item slot  -->
            <template #[`item.fullName`]="{ item }">
              <v-btn
                v-if="!isMobile"
                class="btn-link row-link text--primary"
                @click="goToCertification(item)"
                text
                :aria-label="`${item.fullName}. Open certification details.`"
                >{{ item.fullName }}</v-btn
              ><span v-else class="fullName">{{ item.fullName }}</span>
              <AttributeBadge
                v-if="item.residencyStatus === 'TMP'"
                abbr="TR"
                title="Temporary resident"
                class="ml-1"
              />
            </template>
            <!--  scholastic year item slot  -->
            <template #[`item.scholasticYear`]="{ item }">
              <v-avatar class="scholasticYear white--text mr-2" size="29">
                {{ item.scholasticYear }}
              </v-avatar>
              <span>{{ item.scholasticYear | scholasticYearText }}</span>
            </template>
            <!--  status item slot  -->
            <template #[`item.status`]="{ item }">
              <StatusChip :status="item.status" />
            </template>
            <!--  start date item slot  -->
            <template #[`item.intendedStartDate`]="{ item }">
              <span>{{ item.intendedStartDate | dateFormat }}</span>
            </template>
            <!--  table top (mobile) slot  -->
            <template v-if="isMobile" #top="{ pagination }">
              <div class="table-top">
                <div class="table-top__sort-row">
                  <div class="table-top__results grey--text text--lighten-2">
                    <span class="primary--text font-weight-bold">{{
                      totalItemsText(pagination.itemsLength)
                    }}</span>
                  </div>
                  <div class="table-top__sort">
                    <SortOrderToggle v-model="sortDesc" class="mr-1" />
                    <AdsSelect
                      class="table-top__sort-by"
                      :value.sync="sortBy"
                      :items="sortByItems"
                      aria-label="Sort By"
                      :hide-details="true"
                    />
                  </div>
                </div>
              </div>
            </template>
            <template v-else #top="{ pagination }">
              <v-container class="searchInput ma-0 px-6 py-0" fluid>
                <v-row class="row-line">
                  <v-col class="pt-2 pb-0">
                    <v-text-field
                      v-model="search"
                      class="pt-1"
                      prepend-inner-icon="mdi-magnify"
                      placeholder="Find student"
                      single-line
                      persistent-placeholder
                    />
                  </v-col>
                  <v-col cols="auto" class="results-text pt-2 pb-0">
                    <span>
                      {{ totalItemsText(pagination.itemsLength) }}
                    </span>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </AdsDataTable>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { AdsDataTable, Banner, AdsSelect, AdsButton } from '@nswdoe/doe-ui-core'
import SortOrderToggle from '@/components/SortOrderToggle'
import ToggleButton from '@/components/ToggleButton'
import ListViewFilter from '@/components/ListViewFilter'
import StatusChip from '@/components/StatusChip'
import { mapGetters } from 'vuex'
import { createFilterMatcher, applyFilters } from '@/utils/filters'

import AttributeBadge from '@/components/AttributeBadge.vue'

import {
  CERTIFICATION_STATUS,
  CERTIFICATION_STATUS_OPTIONS,
  SCHOLASTIC_YEARS
} from '@/constants'
import {
  sortBySchoolYear,
  sortByStatus,
  displayDateFormat
} from '@/utils/general'
import TopSlideTransition from '@/components/TopSlideTransition'

const BASE_FILTER_SELECTIONS = {
  status: null,
  scholasticYear: null
}

const Certifications = {
  name: 'Certifications',
  components: {
    TopSlideTransition,
    Banner,
    AdsDataTable,
    ListViewFilter,
    StatusChip,
    AdsSelect,
    AdsButton,
    SortOrderToggle,
    ToggleButton,
    AttributeBadge
  },
  async activated() {
    if (!this.schools?.length) {
      await this.$store.dispatch('fetchSchools')
    }
    const currentSchoolCode =
      this.currentSchool?.schoolCode || this.schoolsForUser[0]?.schoolCode
    if (currentSchoolCode) {
      this.$store.commit('setCurrentSchool', currentSchoolCode)
      return this.$store.dispatch('fetchCertifications', currentSchoolCode)
    }
  },
  data() {
    return {
      showFilters: {
        status: false,
        scholasticYear: false
      },
      filterSelections: { ...BASE_FILTER_SELECTIONS },
      defaultHeaders: [
        {
          text: 'Student name',
          align: 'start',
          sortable: true,
          value: 'fullName'
        },
        {
          text: 'Previous school',
          value: 'previousSchoolName'
        },
        {
          text: 'Scholastic year',
          value: 'scholasticYear',
          sort: sortBySchoolYear
        },
        {
          text: 'Start date',
          value: 'intendedStartDate'
        },
        {
          text: 'Status',
          value: 'status',
          sort: sortByStatus
        }
      ],
      sortBy: 'status',
      sortDesc: false,
      search: '',
      sortedFilteredCertifications: [],
      showFilterListMobile: false,
      options: {
        rowsPerPageItems: [10, 15, 50]
      },
      totalItems: 0
    }
  },
  filters: {
    scholasticYearText(value) {
      return SCHOLASTIC_YEARS[value]
    },
    dateFormat(value) {
      return displayDateFormat(value)
    }
  },
  computed: {
    ...mapGetters([
      'schools',
      'currentSchool',
      'certifications',
      'schoolsForUser'
    ]),
    filterMenuAttrs() {
      return (
        this.isMobile && {
          attach: '#table-container',
          absolute: true
        }
      )
    },
    currentSchoolName() {
      return this.currentSchool?.schoolName
    },
    readyCertificationsCount() {
      return this.certifications.filter(
        (c) => c.status === CERTIFICATION_STATUS.READY
      ).length
    },
    newCertificationsCount() {
      return this.certifications.filter(
        (c) => c.status === CERTIFICATION_STATUS.NEW
      ).length
    },
    statusFilterOptions() {
      const certificationStatuses = new Set(
        this.certifications.map(({ status }) => status)
      )
      return CERTIFICATION_STATUS_OPTIONS.filter((option) =>
        certificationStatuses.has(option.value)
      ).sort((statusA, statusB) => sortByStatus(statusA.value, statusB.value))
    },
    scholasticYearFilterOptions() {
      const certificationScholasticYears = [
        ...new Set(
          this.certifications.map(({ scholasticYear }) => scholasticYear)
        )
      ]
      return certificationScholasticYears
        .map((year) => ({
          label: SCHOLASTIC_YEARS[year],
          value: year
        }))
        .sort((yearA, yearB) => sortBySchoolYear(yearA.value, yearB.value))
    },
    filteredCertifications() {
      const statusFilter = createFilterMatcher(
        this.filterSelections.status,
        'status'
      )
      const scholasticYearFilter = createFilterMatcher(
        this.filterSelections.scholasticYear,
        'scholasticYear'
      )
      return applyFilters(this.certifications, [
        statusFilter,
        scholasticYearFilter
      ])
    },
    isFilterShowing() {
      return Object.values(this.showFilters).some((show) => show)
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    sortByItems() {
      return this.defaultHeaders
        .filter(({ sortable }) => sortable !== false)
        .map(({ value, text }) => ({ value, text }))
    }
  },
  watch: {
    currentSchool() {
      //Clear filters when changing schools
      this.filterSelections = { ...BASE_FILTER_SELECTIONS }
    }
  },
  methods: {
    goToCertification(certification) {
      this.$router.push({
        name: 'Certification',
        query: {
          id: certification.registrationRecordNo,
          schoolCode: certification.schoolCode
        },
        params: { certifications: this.sortedFilteredCertifications }
      })
    },
    setCurrentItems(items) {
      this.sortedFilteredCertifications = items
    },
    totalItemsText(itemsLength) {
      return itemsLength === 1
        ? itemsLength + ' result'
        : itemsLength + ' results'
    }
  }
}

export default Certifications
</script>

<style lang="scss" scoped>
.banner {
  margin-top: 0px;
  background-image: url(../assets/bg-banner@3x.png);
  background-position: right top;
  background-size: auto 218px;
  background-repeat: no-repeat;
  background-color: $ads-navy;
  color: $ads-white;
}

.banner__title {
  font-size: 2.125rem;
  line-height: 1;
  text-align: left;
  padding-left: 0px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0.375rem;
}

.banner__text {
  font-size: 1rem;
  line-height: 40px;
}

.banner__icon {
  margin-top: -0.5rem;
}

.filter-label {
  color: $color-placeholder;
}

.certification-list::v-deep {
  .dv-search {
    width: 100%;
  }

  line-height: 3.5rem;
  @media (max-width: 1250px) {
    width: 100%;
    max-width: 100%;
    margin-left: 0px;
  }
  &.theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border-bottom: 0px;
  }
  &.theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr
    > th {
    color: $color-primary;
    font-weight: normal;
    font-size: 1rem;
  }
  &.theme--light.v-data-table .v-data-table-header th.sortable.active {
    color: $color-primary;
    font-weight: bold;
    font-size: 1rem;
  }
}

#table-container
  > div.v-data-table.dataTable.certification-list.theme--light
  > div.v-data-table__wrapper
  > table
  > tbody
  > tr
  > td
  > div.scholasticYear {
  background-color: $ads-tertiary-blue;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
}

.mobile .certifications-container {
  padding-right: 0 !important;
  margin-left: 0 !important;
}

#table-container {
  position: relative;
}

::v-deep .modal {
  &.filter-menu {
    box-shadow: none;
  }
}

.filter-overlay {
  top: 12px; //match top padding
}

.v-btn.btn-link.row-link {
  font-weight: normal;
  color: pink;
}

::v-deep .v-data-table__mobile-table-row {
  padding: 0.75rem 0 1rem 0.5rem;
  display: inline-block;
  width: 100%;
  position: relative;

  border-bottom: thin solid rgba(0, 0, 0, 0.12);

  .v-data-table__mobile-row {
    &:not(:first-child) {
      padding-right: 4rem;
      height: auto;
      min-height: 1.75rem;

      & .v-data-table__mobile-row__cell {
        text-align: left;
        line-height: 1.5;
        height: 100%;
      }
    }

    &:first-child {
      height: 100%;
      position: absolute;
      top: 0;
      right: 1rem;
      padding: 0;
    }

    &:last-child {
      border-bottom: none !important;
    }
  }

  .fullName {
    font-size: 1rem;
    text-decoration: underline;
  }
}

.mobile-table {
  background-color: transparent;
  & ::v-deep table {
    background-color: $color-white;
  }
}

.table-top,
::v-deep .table-top {
  padding: 0 1.5rem;
}

.table-top {
  &__sort-row {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__sort {
    display: flex;
    align-items: center;
  }

  &__sort-by {
    padding-top: 0;

    &.v-input--is-focused ::v-deep .v-input__slot {
      border: 2px solid $color-primary;
      border-radius: 3px;
    }

    ::v-deep input {
      width: 0;
    }

    & ::v-deep .v-input__slot {
      border: 2px solid transparent;
      margin-bottom: 0;
      padding-left: 0.25rem;
    }

    & ::v-deep .v-select__slot {
      width: auto;
    }

    & ::v-deep .v-select__selection--comma {
      font-weight: bold;
      overflow: visible;
      color: $color-primary;
    }

    & ::v-deep .v-input__icon.v-input__icon--append .v-icon {
      color: $color-primary !important;
    }
  }
}

.table-controls {
  padding: 0 1.5rem;

  &__filter-row {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding-top: 0.75rem;
    margin: 0 -0.75rem;
  }

  &__filter-toggle-button {
    height: 2.25rem;
    width: 2.25rem;
    border: 2px solid transparent;

    &:focus {
      border: 2px solid $color-primary;
    }

    & .v-icon {
      margin-top: 4px;
    }

    &::before {
      content: none;
    }

    &.toggle-button--on {
      background-color: $color-primary;
      &:focus {
        box-shadow: 0 0 0 2px $ads-blue-3;
      }

      & .v-icon {
        color: $color-white !important;
      }
    }
  }

  &__filter-toggle-icon {
    color: $grey-darken1;
  }

  &__search-row {
    display: flex;
    align-items: center;
  }

  &__search {
    & ::v-deep .v-input__control {
      min-height: 54px;
    }

    & ::v-deep .v-text-field__slot {
      padding-left: 0.5rem;
    }
  }
}

.mobile {
  .table-controls__filter-row {
    display: flex;
    flex-wrap: wrap;
    & > * {
      margin-top: 1rem;
    }
  }

  #table-container {
    padding-top: 0;
  }
}

.count-container {
  padding-top: 0 !important;

  & > * {
    height: 100%;
  }

  &--new {
    margin-right: -4px;
  }

  &--ready {
    margin-left: -4px;
  }
}

::v-deep .banner {
  padding-bottom: 16px !important;

  > div {
    padding-top: 16px !important;
  }
}

.searchInput {
  border-bottom: 1px solid #e4e4e6;
  color: #6d7079;
}
::v-deep .theme--light.v-chip {
  border: 0;
}
</style>
