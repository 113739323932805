/* eslint-disable no-unused-vars */
import axios from 'axios'
import store from '@/store'
import { API_ERROR_SUBTYPES } from '@/constants'

const API_BASE = process.env.VUE_APP_ROOT_API

const STUDENT_URL = `${API_BASE}/student`

function getHeaders() {
  return {
    Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
    'x-id-token': sessionStorage.getItem('idToken')
  }
}

function fetchErnStudentInfoBySRN(srn, schoolCode) {
  return axios
    .get(`${STUDENT_URL}/${srn}?schoolCode=${schoolCode}`, {
      headers: getHeaders()
    })
    .then((response) => response)
    .catch((error) => {
      store.dispatch('setAPIError', {
        error,
        fallbackCode: API_ERROR_SUBTYPES.ernStudentInfo
      })
      throw error
    })
}

export default { fetchErnStudentInfoBySRN }
