import { RADIO_OPTIONS, YES_NO, YES_NOT_REQUIRED } from '@/constants'

function isValidOption(options) {
  const validValueOptions = options.map((option) => option.value)
  return (value) => validValueOptions.includes(value)
}

export const isValidYesNo = isValidOption(YES_NO)

export const isValidYesNotRequired = isValidOption(YES_NOT_REQUIRED)

export function isYesValue(value) {
  return value === RADIO_OPTIONS.YES.value
}
