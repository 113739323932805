<template>
  <div class="ml-9 mt-5 mr-9">
    <h2 class="mb-8">Full application details</h2>
    <div v-if="hasError">
      <ErrorComponent />
    </div>
    <AdsExpansionPanel
      v-else
      v-model="expandedPanelIndexes"
      :items="panels"
      data-initial-focus
    >
      <template slot="content1">
        <StudentDetails />
      </template>
      <template slot="content2">
        <ParentCarerDetails />
      </template>
      <template slot="content3">
        <FamilyDetails />
      </template>
      <template slot="content4">
        <OtherFamilyDetails />
      </template>
      <template slot="content5">
        <AdditionalEmergencyContacts />
      </template>
      <template slot="content6">
        <SpecialCircumstances />
      </template>
      <template slot="content7">
        <MedicalDetails />
      </template>
      <template slot="content8">
        <RiskAssessmentDetails />
      </template>
      <template slot="content9">
        <PermissionsAndDeclarations />
      </template>
    </AdsExpansionPanel>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { AdsExpansionPanel } from '@nswdoe/doe-ui-core'
import StudentDetails from '@/components/fullApplication/StudentDetails'
import ParentCarerDetails from '@/components/fullApplication/ParentCarerDetails'
import FamilyDetails from '@/components/fullApplication/FamilyDetails'
import OtherFamilyDetails from '@/components/fullApplication/OtherFamilyDetails'
import MedicalDetails from '@/components/fullApplication/medicalDetails'
import AdditionalEmergencyContacts from '@/components/fullApplication/AdditionalEmergencyContacts'
import SpecialCircumstances from '@/components/fullApplication/SpecialCircumstances'
import RiskAssessmentDetails from '@/components/fullApplication/RiskAssessmentDetails'
import PermissionsAndDeclarations from '@/components/fullApplication/PermissionsAndDeclarations'
import ErrorComponent from '@/components/ErrorComponent'

export default {
  name: 'FullApplication',
  components: {
    AdsExpansionPanel,
    StudentDetails,
    ParentCarerDetails,
    FamilyDetails,
    OtherFamilyDetails,
    MedicalDetails,
    AdditionalEmergencyContacts,
    SpecialCircumstances,
    RiskAssessmentDetails,
    PermissionsAndDeclarations,
    ErrorComponent
  },
  props: {
    srn: {
      type: Number,
      default: null
    },
    schoolCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      hasError: false,
      expandedPanelIndexes: 0,
      panels: [
        { title: 'Student Details' },
        { title: 'Family details - Parent/carer (enrolment owners)' },
        { title: 'Family Details' },
        { title: 'Other family details' },
        { title: 'Additional emergency contacts' },
        { title: 'Special Circumstances - additional information' },
        { title: 'Student medical details and health conditions' },
        { title: 'Student’s history relevant to risk assessment' },
        { title: 'Permissions and declarations of accuracy' }
      ]
    }
  },
  methods: {
    ...mapActions(['fetchErnStudentInfoBySRN'])
  },
  mounted() {
    try {
      this.fetchErnStudentInfoBySRN({
        srn: this.srn,
        schoolCode: this.schoolCode
      })
    } catch (err) {
      this.hasError = true
    }
  }
}
</script>

<style></style>
