<template>
  <v-container class="mt-6">
    <div v-if="emergencyContacts && emergencyContacts.length > 0">
      <div
        v-for="(ec, index) in emergencyContacts"
        :key="index"
        class="dataFields"
      >
        <v-row class="header mb-6">
          Emergency contact: {{ ec.parentCarerGivenName }}
          {{ ec.parentCarerFamilyName }}
        </v-row>
        <!-- ROW#1 -->
        <v-row>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="ec.contactFamilyName"
              label="Family name"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="ec.contactFirstName"
              label="Given name"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="relationship(ec.contactRelationship)"
              label="Relationship to student"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
        </v-row>
        <!-- ROW#2 -->
        <div
          v-for="(contact, cntIndex) in ec.contactDetails"
          :key="cntIndex"
          class="contactFields"
        >
          <div class="contactSectionHeader">
            {{ contactType(contact.contactType) }}
          </div>
          <v-row>
            <v-col cols="12" md="4">
              <AdsTextField
                :value="contactType(contact.contactType)"
                label="Type"
                placeholder=" "
                readonly
                persistent-placeholder
              />
            </v-col>
            <v-col cols="12" md="4">
              <AdsTextField
                :value="displayFormatPhoneNumber(contact.contactValue)"
                :label="contactType(contact.contactType)"
                placeholder=" "
                readonly
                persistent-placeholder
              />
            </v-col>
            <v-col cols="12" md="4">
              <AdsTextField
                :value="contact.comments"
                label="Comments"
                placeholder=" "
                readonly
                persistent-placeholder
              />
            </v-col>
          </v-row>
        </div>
        <v-row />
      </div>
    </div>
    <div v-else>
      <div class="subHeader mb-2">
        No additional emergency contact details available
      </div>
    </div>
  </v-container>
</template>

<script>
import { AdsTextField } from '@nswdoe/doe-ui-core'
import { mapGetters } from 'vuex'
import { RELATIONSHIPS, CONTACT_TYPES } from '@/constants'
import { formatPhoneNumber } from '@/utils/general'

export default {
  name: 'AdditionalEmergencyContacts',
  components: { AdsTextField },
  computed: {
    ...mapGetters({
      selectedErnStudentInfo: 'selectedErnStudentInfo'
    }),
    emergencyContacts() {
      return this.selectedErnStudentInfo?.emergencyContact
    }
  },
  methods: {
    relationship(value) {
      return RELATIONSHIPS.find((item) => item.value === value)?.text
    },
    contactType(value) {
      return CONTACT_TYPES.find((item) => item.code === value)?.value
    },
    displayFormatPhoneNumber(phoneNumber) {
      return formatPhoneNumber(phoneNumber)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .v-input__slot fieldset {
  border: none;
}

::v-deep .col-md-4,
.col-12 {
  padding: 0 !important;
}
</style>
