<template>
  <v-container class="mt-6">
    <div v-if="otherFamilyContacts && otherFamilyContacts.length > 0">
      <div
        v-for="(parent, index) in otherFamilyContacts"
        :key="index"
        class="dataFields"
      >
        <v-row class="header mb-6">
          Other Parent/carer: {{ parent.parentCarerGivenName }}
          {{ parent.parentCarerFamilyName }}
        </v-row>
        <!-- ROW#1 -->
        <v-row>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="title(parent.parentCarerTitle)"
              label="Title"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="gender(parent.parentCarerGenderCode)"
              label="Gender"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="relationship(parent.parentCarerRelation)"
              label="Relationship to student"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
        </v-row>
        <!-- ROW#2 -->
        <v-row>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="parent.parentCarerFamilyName"
              label="Family name"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="parent.parentCarerGivenName"
              label="Given name"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="aboriginality(parent.isParentCarerAboriOrTorres)"
              label="Aboriginality"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
        </v-row>
        <!-- ROW#3 -->
        <div class="subHeader">Statement of account</div>
        <v-row>
          <v-col cols="12">
            <AdsTextField
              :value="statementOfAccountType(parent.statementOfAccount)"
              label="Statement of account"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
        </v-row>
        <!-- ROW#4 -->
        <div class="subHeader">Occupation</div>
        <v-row>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="parent.parentCarerOccupationName"
              label="Occupation"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="parent.parentCarerOccupationGroup"
              label="Occupation group"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
        </v-row>
        <!-- ROW#6 -->
        <div class="subHeader">Education</div>
        <v-row>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="parent.parentCarerSchoolEducationCode"
              label="Highest level of schooling completed"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="parent.parentCarerEducationQualificationCode"
              label="Highest qualification completed"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
        </v-row>
        <!-- ROW#7 -->
        <div
          v-for="(contact, cntIndex) in parent.contactDetails"
          :key="cntIndex"
          class="contactFields"
        >
          <div class="contactSectionHeader">
            {{ contactType(contact.contactType) }}
          </div>
          <v-row>
            <v-col cols="12" md="4">
              <AdsTextField
                :value="contactType(contact.contactType)"
                label="Type"
                placeholder=" "
                readonly
                persistent-placeholder
              />
            </v-col>
            <v-col cols="12" md="4">
              <AdsTextField
                :value="displayFormatPhoneNumber(contact.contactValue)"
                :label="contactType(contact.contactType)"
                placeholder=" "
                readonly
                persistent-placeholder
              />
            </v-col>
            <v-col cols="12" md="4">
              <AdsTextField
                :value="contact.comments"
                label="Comments"
                placeholder=" "
                readonly
                persistent-placeholder
              />
            </v-col>
          </v-row>
        </div>
        <!-- ROW#8 -->
        <div class="subHeader">Residential details</div>
        <v-row>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="addressString(parent.residentialAddress)"
              label="Residential details"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="dateFormat(parent.residentialAddress.startDate)"
              label="Start date"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="dateFormat(parent.residentialAddress.endDate)"
              label="End date"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
        </v-row>
        <!-- ROW#9 -->
        <v-row>
          <v-col cols="12">
            <AdsTextField
              :value="
                yesOrNoWrapper(parent.doesStudentLivesWithParentSometimes)
              "
              label="Student sometimes resides at this address (shared responsibility)?"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
        </v-row>
        <!-- ROW#10 -->
        <div class="subHeader">Correspondence details</div>
        <v-row>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="addressString(parent.correspondenceAddress)"
              label="Correspondence address"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="dateFormat(parent.correspondenceAddress.startDate)"
              label="Start date"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="dateFormat(parent.correspondenceAddress.endDate)"
              label="End date"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-else>
      <div class="subHeader mb-2">No other family details available</div>
    </div>
  </v-container>
</template>

<script>
import { AdsTextField } from '@nswdoe/doe-ui-core'
import { mapGetters } from 'vuex'
import {
  TITLES,
  GENDER_CODES,
  RELATIONSHIPS,
  ABORIGINALITY_CODES,
  STATEMENT_OF_ACCOUNT_TYPES,
  CONTACT_TYPES,
  COUNTRIES
} from '@/constants'
import { displayDateFormat, formatPhoneNumber } from '@/utils/general'

export default {
  name: 'OtherFamilyDetails',
  components: { AdsTextField },
  computed: {
    ...mapGetters({
      selectedErnStudentInfo: 'selectedErnStudentInfo'
    }),
    otherFamilyContacts() {
      // isEnrolmentOwner is set false that meaning that's for other family details data
      const otherFamilyContacts =
        this.selectedErnStudentInfo?.parentCarers.filter(
          (pc) => !pc.isEnrolmentOwner
        )
      return otherFamilyContacts ? otherFamilyContacts : null
    }
  },
  methods: {
    title(value) {
      return TITLES.find((item) => item.value === value)?.text
    },
    gender(value) {
      return GENDER_CODES.find((item) => item.value === value)?.text
    },
    relationship(value) {
      return RELATIONSHIPS.find((item) => item.value === value)?.text
    },
    aboriginality(value) {
      return ABORIGINALITY_CODES.find((item) => item.code === value)?.value
    },
    statementOfAccountType(value) {
      return STATEMENT_OF_ACCOUNT_TYPES.find((item) => item.code === value)
        ?.value
    },
    yesOrNoWrapper(value) {
      return value ? 'Yes' : value === 'false' ? 'No' : ''
    },
    contactType(value) {
      return CONTACT_TYPES.find((item) => item.code === value)?.value
    },
    addressString(address) {
      if (address.addressLine1 !== undefined) {
        if (address.countryCode === 'AUS') {
          return `${address.addressLine1}, ${address.suburbName}, ${address.stateCode} ${address.postCode}`
        }
        const countryName = COUNTRIES.find(
          (item) => item.code === address.countryCode
        )?.value
        return `${address.addressLine1} ${address.addressLine2} ${countryName}`
      }
      return ''
    },
    dateFormat(date) {
      return displayDateFormat(date)
    },
    displayFormatPhoneNumber(phoneNumber) {
      return formatPhoneNumber(phoneNumber)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-input__slot fieldset {
  border: none;
}

::v-deep .col-md-4,
.col-12 {
  padding: 0 !important;
}
</style>
