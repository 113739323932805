<template>
  <span class="error-message error--text">
    <v-icon class="error-message__icon" color="error">
      {{ icon }}
    </v-icon>
    <span class="error-message__text">
      <slot>
        {{ message }}
      </slot>
    </span>
  </span>
</template>

<script>
export default {
  name: 'InputErrorMessage',
  props: {
    message: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'mdi-alert-circle'
    }
  }
}
</script>

<style lang="scss" scoped>
.error-message {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  display: flex;
  align-items: center;

  &__text {
    margin-left: 0.4rem;
  }

  &__icon {
    align-self: flex-start;
  }
}
</style>
