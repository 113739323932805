const getAddressFormatter = (countries, states) => {
  return (value) => {
    try {
      value = value || {}
      const state = states
        ? states.find((state) => state.value === value.stateCode)
        : null

      const country = countries
        ? countries.find((c) => c.value === value.countryCode)
        : null

      const parts = [value.addressLine1, value.addressLine2]
      if (value.countryCode === 'AUS') {
        parts.push(value.suburbName)
        parts.push(state ? state.text : value.stateCode)
        parts.push(value.postCode)
      }
      if (value.countryCode != 'AUS') {
        parts.push(country ? country.text : value.countryCode)
      }

      return parts.filter((part) => part).join(', ')
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error formatting address', e)
    }
  }
}

export default { getAddressFormatter }
