var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',{staticClass:"mt-6"},[(_vm.parentCarers && _vm.parentCarers.length > 0)?_c('div',_vm._l((_vm.parentCarers),function(parent,index){return _c('div',{key:index,staticClass:"dataFields"},[_c('v-row',{staticClass:"header mb-6"},[_vm._v(" Parent/carer with whom this student normally lives ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('AdsTextField',{attrs:{"value":parent.parentCarerGivenName
                  ? parent.parentCarerGivenName
                  : '' + ' ' + parent.parentCarerFamilyName
                  ? parent.parentCarerFamilyName
                  : '',"label":"Name to be used for all correspondence\t","placeholder":" ","readonly":"","persistent-placeholder":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('AdsTextField',{attrs:{"value":_vm.formatAddress(parent.residentialAddress),"label":"Residential Address","placeholder":" ","readonly":"","persistent-placeholder":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('AdsTextField',{attrs:{"value":_vm.formatInArea(_vm.regsitrationData.inZoneInd),"label":"In area?","placeholder":" ","readonly":"","persistent-placeholder":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('AdsTextField',{attrs:{"value":parent.residentialAddress.startDate
                  ? _vm.formatDate(parent.residentialAddress.startDate)
                  : '',"label":"Start date","placeholder":" ","readonly":"","persistent-placeholder":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('AdsTextField',{attrs:{"value":parent.residentialAddress.endDate
                  ? _vm.formatDate(parent.residentialAddress.endDate)
                  : '',"label":"End date","placeholder":" ","readonly":"","persistent-placeholder":""}})],1)],1),_c('div',{staticClass:"subHeader"},[_vm._v("Correspondence details")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('AdsTextField',{attrs:{"value":parent.correspondenceAddress
                  ? _vm.formatAddress(parent.correspondenceAddress)
                  : '',"label":"Correspondence address","placeholder":" ","readonly":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('AdsTextField',{attrs:{"value":parent.correspondenceAddress.startDate
                  ? parent.correspondenceAddress.startDate
                  : '',"label":"Start date","placeholder":" ","readonly":"","persistent-placeholder":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('AdsTextField',{attrs:{"value":parent.correspondenceAddress.endDate
                  ? parent.correspondenceAddress.endDate
                  : '',"label":"End date","placeholder":" ","readonly":"","persistent-placeholder":""}})],1)],1),_c('v-row',{staticClass:"header mb-6"},[_vm._v(" Parent/carer: "+_vm._s(parent.parentCarerGivenName)+" "+_vm._s(parent.parentCarerFamilyName)+" ")]),_vm._l((parent.contactDetails),function(contact,idx){return _c('div',{key:idx,staticClass:"contactFields"},[_c('div',{staticClass:"contactSectionHeader"},[_vm._v(" "+_vm._s(_vm.getContactType(contact.contactType))+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('AdsTextField',{attrs:{"value":_vm.getContactType(contact.contactType),"label":"Type","placeholder":" ","readonly":"","persistent-placeholder":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('AdsTextField',{attrs:{"value":_vm.displayFormatPhoneNumber(contact.contactValue),"label":_vm.getContactType(contact.contactType),"placeholder":" ","readonly":"","persistent-placeholder":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('AdsTextField',{attrs:{"value":contact.comments,"label":"Comments","placeholder":" ","readonly":"","persistent-placeholder":""}})],1)],1)],1)}),_c('v-row')],2)}),0):_c('div',[_c('div',{staticClass:"subHeader mb-2"},[_vm._v("No family details available")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }