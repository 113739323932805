<template>
  <div class="pagination-nav white--text">
    <span class="pagination-nav__text" id="count-text" data-testid="count-text">
      <slot name="text" v-bind="{ currentPage, totalPages }">
        <span class="d-sr-only">Page</span> {{ currentPage }} of
        {{ totalPages }}
      </slot>
    </span>
    <slot name="back" :disabled="disableBack">
      <v-btn
        class="pagination-nav__button pagination-nav__button--back"
        data-testid="back-button"
        v-bind="internalBackAttrs"
        @click="$emit('back')"
      >
        <slot name="back-icon">
          <v-icon :size="iconSize">
            {{ backIcon }}
          </v-icon>
        </slot>
      </v-btn>
    </slot>
    <slot name="forward" :disabled="disabledForward">
      <v-btn
        class="pagination-nav__button pagination-nav__button--forward"
        data-testid="forward-button"
        v-bind="internalForwardAttrs"
        @click="$emit('forward')"
      >
        <slot name="forward-icon">
          <v-icon :size="iconSize">
            {{ forwardIcon }}
          </v-icon>
        </slot>
      </v-btn>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'PaginationNav',
  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    totalPages: {
      type: Number,
      default: 1
    },
    backAttrs: {
      type: Object,
      default: () => ({})
    },
    forwardAttrs: {
      type: Object,
      default: () => ({})
    },
    backIcon: {
      type: String,
      default: 'mdi-chevron-left'
    },
    forwardIcon: {
      type: String,
      default: 'mdi-chevron-right'
    }
  },
  data() {
    return {
      iconSize: 32
    }
  },
  computed: {
    internalBackAttrs() {
      const defaultAttrs = {
        small: true,
        icon: true,
        disabled: this.disableBack,
        'aria-label': `previous page`,
        'aria-describedby': 'count-text'
      }
      return { ...defaultAttrs, ...this.backAttrs }
    },
    internalForwardAttrs() {
      const defaultAttrs = {
        small: true,
        icon: true,
        disabled: this.disabledForward,
        'aria-label': `next page`,
        'aria-describedby': 'count-text'
      }
      return { ...defaultAttrs, ...this.forwardAttrs }
    },
    disableBack() {
      return this.currentPage <= 1
    },
    disabledForward() {
      return this.currentPage >= this.totalPages
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination-nav {
  &__button {
    color: currentColor !important;

    &.theme--light.v-btn.v-btn--disabled {
      color: currentColor !important;

      & .v-icon {
        color: currentColor !important;
        opacity: 0.4;
      }
    }
  }
}
</style>
