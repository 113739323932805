<template>
  <CloseableDialog v-bind="$attrs" v-on="$listeners">
    <template #title=""> Accept this student for enrolment? </template>
    <template #text="">
      The status of the application will be set to “<strong>Accepted</strong>”.
      <br />
      <br />
      This confirms the student will be offered a place at {{ schoolName }}.
    </template>
    <template #actions="">
      <v-btn class="green white--text" @click="confirm">
        <v-icon class="mr-2"> mdi-clipboard-check-outline </v-icon>
        Confirm accept student
      </v-btn>
    </template>
  </CloseableDialog>
</template>

<script>
import CloseableDialog from './CloseableDialog.vue'

export default {
  name: 'AcceptCertificationModal',
  components: { CloseableDialog },
  props: {
    schoolName: {
      type: String,
      default: ''
    }
  },
  methods: {
    confirm() {
      this.$emit('confirm')
      this.$emit('input', false)
    }
  }
}
</script>

<style scoped></style>
