<template>
  <v-dialog v-bind="attrs" v-on="$listeners">
    <template #activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>
    <v-card class="modal">
      <v-card-title class="modal__heading">
        <div class="modal__title">
          <slot name="title" />
        </div>
        <v-btn
          class="modal__close-button"
          icon
          small
          aria-label="close"
          @click="closeModal"
        >
          <v-icon> close </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text v-if="this.$scopedSlots.text" class="modal__text">
        <slot name="text" />
      </v-card-text>

      <v-card-actions v-if="this.$scopedSlots.actions" class="modal__actions">
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AppModal',
  computed: {
    attrs() {
      const defaultProps = {
        'max-width': '630px'
      }
      return { ...defaultProps, ...this.$attrs }
    }
  },
  methods: {
    closeModal() {
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-dialog > .v-card > .v-card__title.modal__heading {
  font-weight: 700;
  font-size: 1.25rem;
  color: $color-primary;
  padding-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
}

.v-dialog > .v-card > .v-card__text.modal__text {
  color: $grey-dark;
  font-size: 1rem;
}
.modal {
  &__close-button {
    align-self: flex-start;
    margin: -1.5rem -1rem 0 auto;
  }

  &__title {
    max-width: calc(100% - 28px);
    word-break: break-word;
  }

  &__actions {
    justify-content: flex-end;

    ::v-deep button.v-btn {
      height: 3rem;
      padding: 0 2rem;
      max-width: 100%;
    }
  }
}
.v-dialog > .v-card > .modal__actions {
  padding: 1rem 1.5rem 2rem;
}
</style>
