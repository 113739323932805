<template>
  <v-radio-group
    class="radio-group"
    v-bind="attrs"
    v-on="$listeners"
    @change="$emit('input', $event)"
  >
    <v-radio
      v-for="item in items"
      :key="item.value"
      class="radio-group__button"
      v-bind="item"
      color="primary lighten-1"
    />
    <template #message="{ message }">
      <InputErrorMessage :message="message" />
    </template>
  </v-radio-group>
</template>

<script>
import InputErrorMessage from '@/components/form/InputErrorMessage'

export default {
  name: 'RadioGroup',
  inheritAttrs: false,
  components: { InputErrorMessage },
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    attrs() {
      const defaultProps = {
        'persistent-hint': true
      }
      return { ...defaultProps, ...this.$attrs }
    }
  }
}
</script>

<style lang="scss" scoped>
.radio-group {
  ::v-deep label.v-label {
    font-weight: normal;
    color: $ads-navy;
  }

  ::v-deep .v-input--radio-group__input legend {
    flex: 1 0 100%;
    margin-bottom: 0.5rem;
    height: auto;
  }

  ::v-deep .mdi-radiobox-blank {
    color: $color-grey-3;
  }
}
</style>
