<template>
  <v-checkbox v-bind="$attrs" v-on="$listeners" ref="oesCheckbox">
    <!-- pass through slots -->
    <template v-for="(_, slotName) of $slots" #[slotName]>
      <slot :name="slotName" />
    </template>
  </v-checkbox>
</template>

<script>
const isIe = () => {
  const ua = window.navigator.userAgent
  return (
    ua.indexOf('MSIE') !== -1 || !!navigator.userAgent.match(/Trident.*rv:11\./)
  )
}

export default {
  name: 'OesCheckbox',
  // override the default value/input for v-model binding
  // https://vuejs.org/v2/guide/components-custom-events.html#Customizing-Component-v-model
  model: {
    prop: 'inputValue',
    event: 'change'
  },
  mounted() {
    if (isIe()) {
      var el = this.$refs.oesCheckbox.$el
      el.onkeydown = (e) => {
        if (e.key === 'Spacebar' || e.key === ' ') {
          e.preventDefault()
          const value = this.$attrs.inputValue

          if (Array.isArray(value)) {
            const valueIndex = value.indexOf(this.$attrs.value)
            if (valueIndex === -1) {
              value.push(this.$attrs.value)
            } else {
              value.splice(valueIndex, 1)
            }
            this.$emit('change', value)
          } else {
            this.$emit('change', !value)
          }
        }
      }
    }
  }
}
</script>
