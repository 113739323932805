<template>
  <Modal v-bind="$attrs" v-on="$listeners" :include-close-button="false">
    <div id="AccessDenyModal">
      <h1 class="heading">
        <v-icon size="36px" class="mr-4"> mdi-alert-circle </v-icon>
        Access Denied
      </h1>
      <p>
        You do not have permission to use this application. Please speak to your
        school principal or contact EDConnect to arrange access.
      </p>
      <div class="actions">
        <v-btn large class="primary logout" @click="$emit('logout')">
          Log out
        </v-btn>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/modal'

export default {
  name: 'AccessDenyModal',
  components: {
    Modal
  }
}
</script>

<style lang="scss" scoped>
#AccessDenyModal {
  font-weight: 500;
  margin: 0 -0.5rem -0.5rem -0.5rem;

  .heading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1.25rem;
  }

  .actions {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: flex-end;
    margin-top: 2rem;

    & button {
      font-weight: bold;
    }
  }

  h1 {
    color: $ads-navy;
    font-weight: bold;
    font-size: 1.25rem;
  }

  p:last-child {
    margin: 0;
  }
}
</style>
