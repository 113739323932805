<template>
  <v-row class="pl-8 pr-8 pl-md-0 pr-md-0 mt-8 mt-md-0 pb-3">
    <v-col class="d-flex justify-md-end flex-column flex-md-row">
      <AdsButton
        secondary
        :disabled="
          !certificationCanBeActioned ||
          !!isOesSupport ||
          !certificationHasChanged
        "
        @click="save"
        button-text="Save changes"
        icon="mdi-content-save-outline"
        data-testid="save-button"
      />
      <AdsButton
        v-if="isPrincipal && certificationCanBeActioned"
        class="green white--text mt-3 mt-md-0"
        @click="accept"
        button-text="Accept student"
        icon="mdi-clipboard-check-outline"
        data-testid="accept-button"
        elevation="0"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { AdsButton } from '@nswdoe/doe-ui-core'
export default {
  name: 'CertificationActions',
  components: { AdsButton },
  computed: {
    ...mapGetters([
      'isPrincipal',
      'isOesSupport',
      'certificationCanBeActioned',
      'certificationHasChanged'
    ])
  },
  methods: {
    save() {
      this.$emit('save')
    },
    accept() {
      this.$emit('accept')
    }
  },
  props: {
    currStatus: String
  }
}
</script>
