<template>
  <div>
    <v-container class="mt-6">
      <div v-if="parentCarers && parentCarers.length > 0">
        <div
          v-for="(parent, index) in parentCarers"
          :key="index"
          class="dataFields"
        >
          <v-row class="header mb-6">
            Parent/carer with whom this student normally lives
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <AdsTextField
                :value="
                  parent.parentCarerGivenName
                    ? parent.parentCarerGivenName
                    : '' + ' ' + parent.parentCarerFamilyName
                    ? parent.parentCarerFamilyName
                    : ''
                "
                label="Name to be used for all correspondence	"
                placeholder=" "
                readonly
                persistent-placeholder
              />
            </v-col>
            <v-col cols="12" md="4">
              <AdsTextField
                :value="formatAddress(parent.residentialAddress)"
                label="Residential Address"
                placeholder=" "
                readonly
                persistent-placeholder
              />
            </v-col>
            <v-col cols="12" md="4">
              <AdsTextField
                :value="formatInArea(regsitrationData.inZoneInd)"
                label="In area?"
                placeholder=" "
                readonly
                persistent-placeholder
              />
            </v-col>
            <v-col cols="12" md="4">
              <AdsTextField
                :value="
                  parent.residentialAddress.startDate
                    ? formatDate(parent.residentialAddress.startDate)
                    : ''
                "
                label="Start date"
                placeholder=" "
                readonly
                persistent-placeholder
              />
            </v-col>
            <v-col cols="12" md="4">
              <AdsTextField
                :value="
                  parent.residentialAddress.endDate
                    ? formatDate(parent.residentialAddress.endDate)
                    : ''
                "
                label="End date"
                placeholder=" "
                readonly
                persistent-placeholder
              />
            </v-col>
          </v-row>
          <div class="subHeader">Correspondence details</div>
          <v-row>
            <v-col cols="12" md="4">
              <AdsTextField
                :value="
                  parent.correspondenceAddress
                    ? formatAddress(parent.correspondenceAddress)
                    : ''
                "
                label="Correspondence address"
                placeholder=" "
                readonly
              />
            </v-col>
            <v-col cols="12" md="4">
              <AdsTextField
                :value="
                  parent.correspondenceAddress.startDate
                    ? parent.correspondenceAddress.startDate
                    : ''
                "
                label="Start date"
                placeholder=" "
                readonly
                persistent-placeholder
              />
            </v-col>
            <v-col cols="12" md="4">
              <AdsTextField
                :value="
                  parent.correspondenceAddress.endDate
                    ? parent.correspondenceAddress.endDate
                    : ''
                "
                label="End date"
                placeholder=" "
                readonly
                persistent-placeholder
              />
            </v-col>
          </v-row>
          <v-row class="header mb-6">
            Parent/carer: {{ parent.parentCarerGivenName }}
            {{ parent.parentCarerFamilyName }}
          </v-row>
          <div
            v-for="(contact, idx) in parent.contactDetails"
            :key="idx"
            class="contactFields"
          >
            <div class="contactSectionHeader">
              {{ getContactType(contact.contactType) }}
            </div>
            <v-row>
              <v-col cols="12" md="4">
                <AdsTextField
                  :value="getContactType(contact.contactType)"
                  label="Type"
                  placeholder=" "
                  readonly
                  persistent-placeholder
                />
              </v-col>
              <v-col cols="12" md="4">
                <AdsTextField
                  :value="displayFormatPhoneNumber(contact.contactValue)"
                  :label="getContactType(contact.contactType)"
                  placeholder=" "
                  readonly
                  persistent-placeholder
                />
              </v-col>
              <v-col cols="12" md="4">
                <AdsTextField
                  :value="contact.comments"
                  label="Comments"
                  placeholder=" "
                  readonly
                  persistent-placeholder
                />
              </v-col>
            </v-row>
          </div>
          <v-row />
        </div>
      </div>
      <div v-else>
        <div class="subHeader mb-2">No family details available</div>
      </div>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { AdsTextField } from '@nswdoe/doe-ui-core'
import { STATES, COUNTRIES, CONTACT_TYPES } from '@/constants'
import formatters from '@/helpers/formatters'
import { displayDateFormat, formatPhoneNumber } from '@/utils/general'

export default {
  name: 'FamilyDetails',
  components: { AdsTextField },
  data() {
    return {
      addressFormatter: formatters.getAddressFormatter(COUNTRIES, STATES)
    }
  },
  computed: {
    ...mapGetters({
      selectedErnStudentInfo: 'selectedErnStudentInfo'
    }),
    parentCarers() {
      //isEnrolmentOwner is set TRUE that meaning that's for family details data
      const parentCarers = this.selectedErnStudentInfo?.parentCarers.filter(
        (pc) => pc.isEnrolmentOwner
      )
      return parentCarers ? parentCarers : null
    },
    regsitrationData() {
      return this.selectedErnStudentInfo?.registration || {}
    }
  },
  methods: {
    formatAddress(address) {
      return this.addressFormatter(address)
    },
    formatInArea(inArea) {
      if (inArea === 'Y') {
        return 'Yes'
      } else if (inArea === 'N') {
        return 'No'
      }

      return ''
    },
    formatDate(date) {
      return displayDateFormat(date)
    },
    getContactType(type) {
      return CONTACT_TYPES.find((item) => item.code === type)?.value
    },
    displayFormatPhoneNumber(phoneNumber) {
      return formatPhoneNumber(phoneNumber)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .v-input__slot fieldset {
  border: none;
}

::v-deep .col-md-4,
.col-12 {
  padding: 0 !important;
}
</style>
