<template>
  <Chip
    v-if="status !== null"
    v-bind="chipProps"
    class="status-chip"
    pill
    small
  />
</template>

<script>
import { CERTIFICATION_STATUS } from '@/constants'
import { Chip } from '@nswdoe/doe-ui-core'

export const CHIP_CONFIG = {
  [CERTIFICATION_STATUS.NEW]: {
    textColor: 'white',
    color: 'ads-warning-orange',
    class: 'primary--border',
    text: CERTIFICATION_STATUS.NEW
  },
  [CERTIFICATION_STATUS.READY]: {
    textColor: 'white',
    color: 'ads-primary-blue-dark',
    text: CERTIFICATION_STATUS.READY
  },
  [CERTIFICATION_STATUS.IN_PROGRESS]: {
    textColor: 'white',
    color: 'ads-tertiary-blue',
    text: CERTIFICATION_STATUS.IN_PROGRESS
  },
  [CERTIFICATION_STATUS.DECLINED]: {
    textColor: 'white',
    color: 'red',
    text: CERTIFICATION_STATUS.DECLINED
  },
  [CERTIFICATION_STATUS.ACCEPTED]: {
    textColor: 'white',
    color: 'ads-success-green',
    text: CERTIFICATION_STATUS.ACCEPTED
  },
  [CERTIFICATION_STATUS.WITHDRAWN]: {
    textColor: 'white',
    color: 'ads-secondary-grey',
    text: CERTIFICATION_STATUS.WITHDRAWN
  },
  default: {
    color: 'primary',
    outlined: true
  }
}

export default {
  name: 'OoaStatusChip',
  props: {
    status: {
      type: String,
      default: 'default'
    }
  },
  components: {
    Chip
  },
  computed: {
    chipProps() {
      return (
        CHIP_CONFIG[this.status] || {
          ...CHIP_CONFIG.default,
          text: this.status.toUpperCase()
        }
      )
    }
  }
}
</script>
<style scoped lang="scss">
.status-chip::v-deep {
  .v-chip__content span {
    display: inline-block;
    text-align: center;
    min-width: 100px;
    font-weight: bold;
  }
}

.theme--light .primary--border {
  border-color: $color-primary !important;
  border-width: thin;
  border-style: solid;
}
.ads-warning-orange {
  background-color: $ads-warning-orange !important;
}
.ads-tertiary-blue {
  background-color: $ads-tertiary-blue !important;
}
.ads-primary-blue-dark {
  background-color: $ads-primary-blue-dark !important;
}
.ads-success-green {
  background-color: $ads-success-green !important;
}
.ads-secondary-grey {
  background-color: $ads-secondary-grey !important;
}
</style>
