<template>
  <CloseableDialog v-bind="$attrs" v-on="$listeners" @input="handleToggle">
    <template #title>
      {{ errorTitle }}
    </template>

    <template #text>
      <p class="mb-8">
        {{ errorText }}
      </p>
    </template>
    <template #actions>
      <v-btn class="primary white--text" @click="retry">
        <v-icon class="mr-2"> mdi-refresh </v-icon>
        Back to list
      </v-btn>
    </template>
  </CloseableDialog>
</template>

<script>
import CloseableDialog from './CloseableDialog.vue'

import { mapMutations } from 'vuex'

export default {
  name: 'ErrorModal',
  components: { CloseableDialog },
  props: {
    error: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    retry() {
      this.$emit('retry')
      this.$emit('input', false)
    },
    ...mapMutations(['resetError']),
    handleToggle(val) {
      if (!val) {
        this.resetError()
      }
    }
  },
  computed: {
    errorText() {
      return this.error?.errorObject?.text
    },
    errorTitle() {
      return this.error?.errorObject?.title
    }
  }
}
</script>
