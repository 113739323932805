<template>
  <div>
    <div class="mx-6 mx-md-12 pt-4">
      <div class="d-flex align-start">
        <div
          class="app-settings__circle-icon d-flex align-center justify-center"
        >
          <v-icon size="30" class="material-icons-outlined"> mail </v-icon>
        </div>
        <div class="ml-3 mt-3">
          <h3 class="app-settings-heading">
            Daily summary email - enrolment applications requiring certification
          </h3>
        </div>
      </div>
      <div
        class="d-flex align-start"
        :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
      >
        <v-radio-group
          label="Choose whether you wish to receive a daily email:"
          :value="emailSetting"
          @change="$emit('updatedEmailSetting', $event)"
        >
          <v-radio
            v-for="item in emailSettingOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            class="py-1"
          />
        </v-radio-group>
      </div>
    </div>
  </div>
</template>

<script>
import { EMAIL_SETTING_PREFERENCES_OPTIONS } from '@/constants'
export default {
  name: 'AppSettingsEmailPreferences',
  props: {
    emailSetting: {
      type: Boolean,
      default: true
    },
    currentYearTimeFrame: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      emailSettingOptions: EMAIL_SETTING_PREFERENCES_OPTIONS
    }
  }
}
</script>

<style lang="scss" scoped>
.v-input--radio-group::v-deep legend {
  color: $color-primary;
  font-weight: 700;
  font-size: 16px;
}

.v-radio::v-deep .v-label {
  min-height: 8px;
  font-size: 16px;
  color: $color-primary !important;
  height: 20px;
  line-height: 20px;
}
</style>
