<template>
  <Alert
    v-if="showWarning"
    in-page
    :allow-dismiss="true"
    data-testid="ie-warning"
    type="warning"
    text="You may experience issues running this application in this browser."
    subtext="Please use Google Chrome, Safari or Microsoft Edge"
    @dismiss="acknowledge"
  />
</template>

<script>
import { IeBlocker, Alert } from '@nswdoe/doe-ui-core'

const ACK_SESSION_KEY = 'pc.ieAck'

export default {
  name: 'IeWarning',
  components: {
    Alert
  },
  data() {
    return {
      isIEBrowser: IeBlocker.isIe(),
      dataWarningAcknowledged: sessionStorage.getItem(ACK_SESSION_KEY)
    }
  },
  computed: {
    warningAcknowledged: {
      get() {
        return this.dataWarningAcknowledged
      },
      set(value) {
        sessionStorage.setItem(ACK_SESSION_KEY, value)
        this.dataWarningAcknowledged = value
      }
    },
    showWarning() {
      return !this.warningAcknowledged && this.isIEBrowser
    }
  },
  methods: {
    acknowledge() {
      this.warningAcknowledged = true
    }
  }
}
</script>
