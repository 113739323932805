<template>
  <div v-if="this.singleSchool" style="width: 100%">
    <span
      class="d-sr-only"
      role="alert"
      :aria-label="this.schools[0].schoolName"
    />
    <DataSwitcher v-on="$listeners" v-bind="attrs" />
  </div>
  <DataSwitcher v-else v-on="$listeners" v-bind="attrs" />
</template>

<script>
import { DataSwitcher } from '@nswdoe/doe-ui-core'
export default {
  name: 'SchoolSwitcher',
  inheritAttrs: false,
  components: { DataSwitcher },
  props: {
    schools: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    attrs() {
      const defaults = {
        id: 'school-switcher',
        'data-testid': 'school-switcher',
        'item-text': 'schoolName',
        'item-value': 'schoolCode',
        label: 'Search for a school',
        'prepend-inner-icon': '',
        clearable: false,
        class: this.singleSchool
          ? 'school-switcher--single school-switcher'
          : 'school-switcher',
        'append-icon': this.singleSchool ? '' : '$dropdown',
        disabled: this.singleSchool,
        items: this.schools
      }
      return { ...defaults, ...this.$attrs }
    },
    singleSchool() {
      return this.schools.length === 1
    }
  }
}
</script>

<style lang="scss" scoped>
.school-switcher {
  min-width: 20rem;
  &--single ::v-deep input {
    color: $color-white;
  }
}
</style>
