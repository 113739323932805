<template>
  <CloseableDialog v-bind="$attrs" v-on="$listeners" @input="handleToggle">
    <template #title>
      {{ errorText }}
    </template>

    <template v-if="updateConflictError" #text>
      <p class="grey--text">
        Another user has already made changes to this student application.
      </p>
      <p class="grey--text">
        Please select <span class="font-weight-bold"> Reload </span> to get the
        latest details, then try again.
      </p>
    </template>

    <template v-else #text>
      <p class="mb-8">Please retry, or try again later.</p>

      <v-card class="grey lighten-7" elevation="0">
        <v-card-title class="grey--text font-weight-bold">
          Error Id: {{ errorId }}
        </v-card-title>
        <v-card-text class="grey--text">
          Error Code: {{ errorCode }}
        </v-card-text>
      </v-card>
    </template>
    <template #actions>
      <v-btn class="primary white--text" @click="retry">
        <v-icon class="mr-2"> mdi-refresh </v-icon>
        {{ updateConflictError ? 'Reload' : 'Retry' }}
      </v-btn>
    </template>
  </CloseableDialog>
</template>

<script>
import CloseableDialog from './CloseableDialog.vue'

import { mapMutations } from 'vuex'
import { API_ERROR_SUBTYPES } from '@/constants'

export default {
  name: 'ErrorModal',
  components: { CloseableDialog },
  props: {
    error: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    retry() {
      this.$emit('retry')
      this.$emit('input', false)
    },
    ...mapMutations(['resetError']),
    handleToggle(val) {
      if (!val) {
        this.resetError()
      }
    }
  },
  computed: {
    errorObject() {
      return this.error.errorObject || {}
    },
    stackTrace() {
      return this.errorObject.stack || null
    },
    response() {
      return this.error.response || {}
    },
    errorList() {
      return (this.response.data && this.response.data.errors) || []
    },
    codeText() {
      return (
        (this.errorList[0] && this.errorList[0].error_code) ||
        this.error.fallbackCode
      )
    },
    codeStatus() {
      return (
        (this.errorList[0] && this.errorList[0].status) || this.response.status
      )
    },
    errorCode() {
      return (
        (this.codeText &&
          this.codeStatus &&
          `${this.codeText}-${this.codeStatus}`) ||
        null
      )
    },
    errorId() {
      return (this.errorList[0] && this.errorList[0].id) || null
    },
    errorText() {
      if (this.updateConflictError) {
        return 'Changes cannot be saved at the moment'
      } else {
        switch (this.codeText) {
          case API_ERROR_SUBTYPES.acceptCertification:
            return 'We’re sorry - we are unable to process this application at the moment'
          case API_ERROR_SUBTYPES.certificationDetails:
            return 'We’re sorry - we cannot retrieve this application at the moment'
          case API_ERROR_SUBTYPES.declineCertification:
            return 'We’re sorry - we are unable to process this application at the moment'
          case API_ERROR_SUBTYPES.listCertification:
            return 'We’re sorry - an error has occurred'
          default:
            return 'We’re sorry - changes cannot be saved at the moment'
        }
      }
    },
    updateConflictError() {
      // if there is a update conflict, BE will throw a 409 error.
      return this.codeStatus === 409
    }
  }
}
</script>
