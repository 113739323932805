<template>
  <v-container class="mt-6">
    <v-row>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="permissionToPublish"
          label="Permission to publish?"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="onlineService"
          label="Online services?"
          placeholder=" "
          readonly
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="signatureOfParentCarer"
          label="Signature of parent/carer?"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="declarationDate"
          label="Declaration date"
          placeholder=" "
          readonly
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AdsTextField } from '@nswdoe/doe-ui-core'
import { mapGetters } from 'vuex'
import { displayDateFormat, yesOrNoFromBooleanOrString } from '@/utils/general'

export default {
  name: 'PermissionsAndDeclarations',
  components: { AdsTextField },
  computed: {
    ...mapGetters(['selectedErnStudentInfo']),
    registration() {
      return this.selectedErnStudentInfo?.registration || {}
    },
    studentDetails() {
      return this.selectedErnStudentInfo?.student || {}
    },
    permissionToPublish() {
      return yesOrNoFromBooleanOrString(
        this.studentDetails?.permissionToPublish
      )
    },
    onlineService() {
      return yesOrNoFromBooleanOrString(this.studentDetails?.onlineService)
    },
    signatureOfParentCarer() {
      return yesOrNoFromBooleanOrString(
        this.registration?.studentDeclarationInd
      )
    },
    declarationDate() {
      return displayDateFormat(this.registration?.studentDeclarationDate)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-input__slot fieldset {
  border: none;
}

::v-deep .col-md-4,
.col-12 {
  padding: 0 !important;
}
</style>
