<template>
  <v-container class="mt-6">
    <div v-if="studentDetails">
      <v-row class="header"> Risk Assessment </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <AdsTextField
            :value="previousHistoryInd"
            label="Has a history of risk to students or staff?"
            placeholder=" "
            readonly
          />
        </v-col>
        <v-col cols="12" md="4" v-if="hasRiskOfAnyTypeDetails">
          <AdsTextArea
            :value="studentDetails.riskOfAnyTypeDetails"
            label="History of risk"
            placeholder=" "
            counter="2048"
            class="pr-5"
            readonly
          />
        </v-col>
        <v-col cols="12" md="4">
          <AdsTextField
            :value="hasHealthProfContactYesNo"
            label="Are there health professionals with knowledge of risk history?"
            placeholder=" "
            readonly
          />
        </v-col>
        <v-col cols="12" md="4" v-if="hasHealthProfContact">
          <AdsTextField
            :value="studentDetails.healthProfContact"
            label="Contact details of health professionals"
            placeholder=" "
            readonly
          />
        </v-col>
        <v-col cols="12" md="4">
          <AdsTextField
            :value="anyHistoryViolentBehaviour"
            label="Has a history of violent behaviour?"
            placeholder=" "
            readonly
          />
        </v-col>
        <v-col cols="12" md="4" v-if="hasAnyHistoryViolentBehaviour">
          <AdsTextArea
            :value="studentDetails.violentBehaviourDetails"
            label="History of violent behaviour?"
            placeholder=" "
            counter="2048"
            class="pr-5"
            readonly
          />
        </v-col>
      </v-row>
      <v-row class="subHeader"> Suspension/expulsion </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <AdsTextField
            :value="hasSuspendedorExpelled"
            label="Suspended/expelled from any previous school?"
            placeholder=" "
            readonly
          />
        </v-col>
        <v-col cols="12" md="4">
          <AdsTextField
            :value="actualViolence"
            label="For actual violence?"
            placeholder=" "
            readonly
          />
        </v-col>
        <v-col cols="12" md="4">
          <AdsTextField
            :value="prossessionOfWeapon"
            label="For possession of weapon?"
            placeholder=" "
            readonly
          />
        </v-col>
        <v-col cols="12" md="4">
          <AdsTextField
            :value="threatOfViolence"
            label="For threat of violence or intimidation?"
            placeholder=" "
            readonly
          />
        </v-col>
        <v-col cols="12" md="4">
          <AdsTextField
            :value="illegalDrugs"
            label="For illegal drugs?"
            placeholder=" "
            readonly
          />
        </v-col>
        <v-col cols="12" md="4">
          <AdsTextField
            :value="hasOtherIncident"
            label="Any other incidents of this sort?"
            placeholder=" "
            readonly
          />
        </v-col>
        <v-col cols="12" md="4" v-if="hasOtherIncidentBoolean">
          <AdsTextArea
            :value="studentDetails.otherIncidentDetails"
            label="Other incidents"
            placeholder=" "
            counter="2048"
            class="pr-5"
            readonly
          />
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <div class="subHeader">No risk assessment details available</div>
    </div>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { AdsTextField, AdsTextArea } from '@nswdoe/doe-ui-core'
import { COUNTRIES, STATES } from '@/constants'
import formatters from '@/helpers/formatters'
import { yesOrNoFromBooleanOrString, isValueNotNull } from '@/utils/general'

export default {
  name: 'RiskAssessmentDetails',
  components: { AdsTextField, AdsTextArea },
  data() {
    return {
      addressFormatter: formatters.getAddressFormatter(COUNTRIES, STATES)
    }
  },
  computed: {
    ...mapGetters(['selectedErnStudentInfo']),
    studentDetails() {
      return this.selectedErnStudentInfo
    },
    previousHistoryInd() {
      return yesOrNoFromBooleanOrString(this.studentDetails?.previousHistoryInd)
    },
    hasRiskOfAnyTypeDetails() {
      return isValueNotNull(this.studentDetails?.riskOfAnyTypeDetails)
    },
    hasHealthProfContact() {
      return isValueNotNull(this.studentDetails?.healthProfContact)
    },
    hasHealthProfContactYesNo() {
      return this.hasHealthProfContact === true ? 'Yes' : 'No'
    },
    anyHistoryViolentBehaviour() {
      return yesOrNoFromBooleanOrString(
        this.studentDetails?.anyHistoryViolentBehaviour
      )
    },
    hasAnyHistoryViolentBehaviour() {
      return this.anyHistoryViolentBehaviour === 'Yes'
    },
    hasSuspendedorExpelled() {
      return yesOrNoFromBooleanOrString(
        this.studentDetails?.hasSuspendedorExpelled
      )
    },
    actualViolence() {
      return yesOrNoFromBooleanOrString(
        this.studentDetails?.reasonForSuspension?.actualViolence
      )
    },
    prossessionOfWeapon() {
      return yesOrNoFromBooleanOrString(
        this.studentDetails?.reasonForSuspension?.prossessionOfWeapon
      )
    },
    threatOfViolence() {
      return yesOrNoFromBooleanOrString(
        this.studentDetails?.reasonForSuspension?.threatOfViolence
      )
    },
    illegalDrugs() {
      return yesOrNoFromBooleanOrString(
        this.studentDetails?.reasonForSuspension?.illegalDrugs
      )
    },
    hasOtherIncident() {
      return yesOrNoFromBooleanOrString(this.studentDetails?.hasOtherIncident)
    },
    hasOtherIncidentBoolean() {
      return this.hasOtherIncident === 'Yes'
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .v-input__slot fieldset {
  border: none;
}

::v-deep .col-md-4,
.col-12 {
  padding: 0 !important;
}
</style>
