import { merge } from 'lodash'

const defaultNotification = {
  attr: {
    timeout: -1,
    value: true,
    bottom: true,
    left: true,
    color: 'white'
  },
  icon: 'mdi-check',
  iconColor: 'green'
}
const notificationsModule = {
  state: {
    notifications: []
  },
  getters: {
    notifications(state) {
      return state.notifications
    }
  },
  mutations: {
    pushNotification(state, notification) {
      let mergedNotification = merge({ ...defaultNotification }, notification)
      state.notifications = []
      state.notifications.push(mergedNotification)
    }
  }
}

export default notificationsModule
