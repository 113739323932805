const timeoutDuration = process.env.VUE_APP_ACTIVITY_TIMEOUT_MINUTES * 60000

const authModule = {
  state: {
    showInactivityBanner: false,
    lastActivityTimestamp: null
  },
  getters: {
    timeoutTimestamp: (state) =>
      state.lastActivityTimestamp
        ? state.lastActivityTimestamp + timeoutDuration
        : null
  },
  mutations: {
    setShowInactivityBanner(state, value) {
      state.showInactivityBanner = value
    },
    setLastActivityTimestamp(state, value) {
      state.lastActivityTimestamp = value
    }
  },
  actions: {
    beginFinalInactivityTimer({ commit }) {
      this._vm.$activityMonitor.deactivate()
      commit('setShowInactivityBanner', true)
    },
    clearTokens() {
      ;['idToken', 'accessToken', 'refreshToken'].forEach((tokenName) =>
        sessionStorage.removeItem(tokenName)
      )
    },
    authenticateUser() {
      this._vm.$auth.DET.startAuth()
    },
    async refreshTokens() {
      const refreshToken = sessionStorage.getItem('refreshToken')
      if (refreshToken) {
        const { id_token, refresh_token, access_token } =
          await this._vm.$auth.DET.refreshTokens(refreshToken)
        if (id_token) {
          sessionStorage.setItem('idToken', id_token)
        }
        if (access_token) {
          sessionStorage.setItem('accessToken', access_token)
        }
        if (refresh_token) {
          sessionStorage.setItem('refreshToken', refreshToken)
        }
        return !!id_token
      } else {
        throw 'No refresh token was found'
      }
    }
  }
}

export default authModule
