import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import OAuth from './plugins/OAuth/OAuth'
import { ActivityMonitor } from '@nswdoe/doe-ui-core'
import setupAxios from '@/api/utils'
import axios from 'axios'
import GTM from '@gtm-support/vue2-gtm'

setupAxios(axios, store)

Vue.use(ActivityMonitor, {
  events: ['keydown', 'mousedown'],
  // Trigger inactivity callback 'warning minutes' before 'timeout minutes'.
  // e.g. 5 minutes (warning duration) before the 60 minutes (timeout duration)
  // of inactivity (i.e. after 55 minutes of inactivity).
  inactivityTimeout:
    (process.env.VUE_APP_ACTIVITY_TIMEOUT_MINUTES -
      process.env.VUE_APP_ACTIVITY_TIMEOUT_WARNING_MINUTES) *
    60000,
  throttlerTimeout: 1000,
  onInactiveUser: () => {
    store.dispatch('beginFinalInactivityTimer')
  }
})

// Config the App root instance (oAuth plugin, etc.)
Vue.config.productionTip = false

// Global event bus
Vue.prototype.$eventHub = new Vue()

Vue.use(OAuth, {
  router,
  authProviderConfig: {
    DET: {
      urls: {
        authorise: process.env.VUE_APP_DET_AUTH_URI,
        token: process.env.VUE_APP_DET_TOKEN_URI
      },
      params: {
        client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
        response_type: 'code',
        scope: 'openid'
      }
    }
  },
  goToRouteOnRedirect: true,
  appStateToPersist: () => ({}),
  storagePrefix: 'si.auth',
  async onAuthorisedRedirect(tokens) {
    sessionStorage.setItem('accessToken', tokens.access)
    sessionStorage.setItem('idToken', tokens.id)
    sessionStorage.setItem('refreshToken', tokens.refresh)
    store.dispatch('setCurrentUser')
    await store.dispatch('fetchSchools')
  },
  onRedirectError: () => {}
})

const gtmQueryParams =
  process.env.VUE_APP_ENV_NAME === 'PROD'
    ? {}
    : {
        gtm_auth: 'bQr-etpY6eNEf3VD9V9IFQ',
        gtm_preview: 'env-3',
        gtm_cookies_win: 'x'
      }

// Adds Google Tag Manager tracking
Vue.use(GTM, {
  id: process.env.VUE_APP_GTM_ID,
  debug:
    process.env.VUE_APP_ENV_NAME === 'TEST' ||
    process.env.VUE_APP_ENV_NAME === 'PREPROD',
  vueRouter: router,
  queryParams: gtmQueryParams
})

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App, {})
}).$mount('#app')
