import _get from 'lodash/get'

//Takes in an array of selected filter options and a path to the value to compare to on the object.
//Returns a function that takes in an item as its singular argument and returns true if either the
//selected options array is empty (i.e. no filter options selected) OR if the value at the path on the object,
//appears in the options array.
export function createFilterMatcher(selectedOptions, valuePath) {
  return (item) =>
    !(selectedOptions?.length > 0) ||
    selectedOptions.includes(_get(item, valuePath))
}

//Takes an array of items and an array of filters. Filters functions that take a SINGLE item
//as their argument and return true if the item matches or false if the item does not match.
export function applyFilters(items, filters) {
  return items?.filter((item) => filters.every((filter) => filter(item)))
}
