<template>
  <div aria-live="assertive">
    <transition name="fade">
      <div v-if="visible" class="AppSpinner" role="alert" aria-busy="true">
        <v-progress-circular :size="80" indeterminate />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'AppSpinner',
  computed: {
    visible() {
      return this.$store.getters.isSpinner
    }
  }
}
</script>

<style scoped lang="scss">
.AppSpinner {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8rem;
  background-color: rgba(87, 101, 119, 0.28); // Shades the background slightly
  color: white;
  z-index: 10000;
  cursor: default;
}

// Classes auto generated by Vue transition
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
