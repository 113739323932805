import certificationsAPI from 'api/certifications'
import ernStudentInfoAPI from 'api/ernStudentInfo'
import {
  HIDDEN_FIELD_DEFAULTS,
  RADIO_OPTIONS,
  CERTIFICATION_STATUS,
  API_ERROR_SUBTYPES
} from '@/constants'
import router from '@/router'
import _isEqual from 'lodash/isEqual'
import _cloneDeep from 'lodash/cloneDeep'
import _pick from 'lodash/pick'

const isNotYes = (value) => value !== RADIO_OPTIONS.YES.value

const certificationsModule = {
  state: {
    certifications: [],
    selectedCertification: {},
    originalSelectedCertification: {},
    isSelectedCertificationValid: null,
    selectedErnStudentInfo: {}
  },
  getters: {
    certifications: (state) => state.certifications,
    selectedCertification: (state) => state.selectedCertification,
    isSelectedCertificationValid: (state) => state.isSelectedCertificationValid,
    hidePlanningQuestions: (state) => {
      const {
        circumstancesHistoryAssessed,
        personalisedLearningSupport,
        consultWithParentsConducted
      } = state?.selectedCertification || {}
      return (
        isNotYes(circumstancesHistoryAssessed) ||
        isNotYes(personalisedLearningSupport) ||
        isNotYes(consultWithParentsConducted)
      )
    },
    hiddenFields: (state, getters) => {
      const {
        riskAssessmentRequired,
        circumstancesHistoryAssessed,
        personalisedLearningSupport
      } = state?.selectedCertification || {}
      const { hidePlanningQuestions } = getters
      return {
        riskAssessmentRequired: isNotYes(circumstancesHistoryAssessed),
        riskAssessmentConducted:
          isNotYes(circumstancesHistoryAssessed) ||
          isNotYes(riskAssessmentRequired),
        personalisedLearningSupport: isNotYes(circumstancesHistoryAssessed),
        consultWithParentsConducted:
          isNotYes(personalisedLearningSupport) ||
          isNotYes(circumstancesHistoryAssessed),
        planningPersonalisedLearningSupport: hidePlanningQuestions,
        behaviourPlanViolenceDeveloped: hidePlanningQuestions,
        behaviourPlanOtherDeveloped: hidePlanningQuestions,
        healthCarePlanDeveloped: hidePlanningQuestions,
        emergencyRespPlanDeveloped: hidePlanningQuestions
      }
    },
    visibleFields: (state, getters) => {
      const alwaysVisibleFields = [
        'enrolmentInterviewConducted',
        'circumstancesHistoryAssessed',
        'commDocumentedProvisions',
        'enrolmentNotes'
      ]
      let conditionalVisibleFields = Object.entries(
        getters.hiddenFields
      ).reduce((acc, [key, value]) => {
        if (!value) {
          acc.push(key)
        }
        return acc
      }, [])
      return [...alwaysVisibleFields, ...conditionalVisibleFields]
    },
    //Whether the certification can be accepted or declined (does not consider validation)
    certificationCanBeActioned: (state, getters) => {
      const selectedCertificationStatus = getters.selectedCertification.status
      return (
        selectedCertificationStatus === CERTIFICATION_STATUS.NEW ||
        selectedCertificationStatus === CERTIFICATION_STATUS.IN_PROGRESS ||
        selectedCertificationStatus === CERTIFICATION_STATUS.READY
      )
    },
    certificationShouldBeDisabled: (state, getters) => {
      const selectedCertificationStatus = getters.selectedCertification.status
      return (
        selectedCertificationStatus === CERTIFICATION_STATUS.DECLINED ||
        selectedCertificationStatus === CERTIFICATION_STATUS.ACCEPTED ||
        selectedCertificationStatus === CERTIFICATION_STATUS.WITHDRAWN ||
        getters.isOesSupport
      )
    },
    certificationHasChanged: (state, getters) => {
      return !_isEqual(
        _pick(state.selectedCertification, getters.visibleFields),
        _pick(state.originalSelectedCertification, getters.visibleFields)
      )
    },
    selectedErnStudentInfo: (state) => state.selectedErnStudentInfo
  },
  mutations: {
    setCertifications: function (state, certifications) {
      state.certifications = certifications
    },
    setSelectedCertification: function (state, certification) {
      state.selectedCertification = certification
    },
    setInitialSelectedCertification: function (state, certification) {
      state.selectedCertification = certification
      state.originalSelectedCertification = _cloneDeep(certification)
    },
    updateCertification(state, updatedCert) {
      const currentCert = state.selectedCertification || {}
      state.selectedCertification = { ...currentCert, ...updatedCert }
    },
    setSelectedCertificationValid(state, isValid) {
      state.isSelectedCertificationValid = isValid
    },
    clearSelectedCertification(state) {
      state.selectedCertification = {}
      state.originalSelectedCertification = {}
      state.isSelectedCertificationValid = null
    },
    setSelectedErnStudentInfo: function (state, selectedErnStudentInfo) {
      state.selectedErnStudentInfo = selectedErnStudentInfo
    }
  },
  actions: {
    fetchCertifications({ commit }, schoolCode) {
      if (!this.getters.isLoading) {
        commit('setLoading', true)
      }
      return certificationsAPI
        .fetchCertifications(schoolCode)
        .then((response) => {
          commit('setCertifications', response.data.principalCertifications)
        })
        .finally(() => {
          if (this.getters.isLoading) {
            commit('setLoading', false)
          }
        })
    },
    fetchCertificationById({ commit }, { id, schoolCode }) {
      if (!this.getters.isLoading) {
        commit('setLoading', true)
      }

      return certificationsAPI
        .fetchCertificationById(id, schoolCode)
        .then((response) => {
          if (response) {
            commit(
              'setInitialSelectedCertification',
              response.data.principalCertification
            )
          }
        })
        .finally(() => {
          if (this.getters.isLoading) {
            commit('setLoading', false)
          }
        })
    },
    // If a field is hidden its value needs to be updated according to ERN's logic before it is sent to ERN.
    // The values to assign a hidden field is given in the HIDDEN_FIELD_DEFAULTS object.
    // If any of the fields defined in this object are hidden when this action is called
    // (as given by getters.hiddenFields), they will be updated to the value in that object.
    updateHiddenFieldValues({ commit, getters }) {
      const fieldsToMerge = Object.entries(getters.hiddenFields).reduce(
        (fieldsToMerge, [field, isHidden]) =>
          isHidden
            ? { ...fieldsToMerge, [field]: HIDDEN_FIELD_DEFAULTS[field] }
            : fieldsToMerge,
        {}
      )
      commit('setSelectedCertification', {
        ...getters.selectedCertification,
        ...fieldsToMerge
      })
    },
    async acceptCertification({ dispatch, commit, getters }) {
      try {
        commit('setLoading', true) //NOTE: we don't setLoading false in happy path as it's done as part of route change
        await dispatch(
          'updateCertification',
          API_ERROR_SUBTYPES.acceptCertification
        )
        await dispatch('certifyCertification')

        let acceptNotification = {
          id: new Date(),
          attr: {
            timeout: 5000
          },
          icon: 'mdi-clipboard-check-outline',
          iconColor: 'green',
          message:
            `<strong>${getters.selectedCertification.firstName} ${getters.selectedCertification.familyName}</strong>` +
            ' has been accepted for enrolment. The ERN record has been updated'
        }
        commit('pushNotification', acceptNotification)
        await router.push({ name: 'Certifications' })
      } catch (e) {
        commit('setLoading', false)
        //TODO: Once error handling is defined: Handle failed save or certify
      }
    },
    async declineCertification({ dispatch, commit, getters }) {
      try {
        commit('setLoading', true) //NOTE: we don't setLoading false in happy path as it's done as part of route change
        await dispatch(
          'updateCertification',
          API_ERROR_SUBTYPES.declineCertification
        )
        await dispatch('withdrawCertification')
        let declineNotification = {
          id: new Date(),
          attr: {
            timeout: 5000
          },
          icon: 'mdi-cancel',
          iconColor: 'red',
          message:
            `<strong>${getters.selectedCertification.firstName} ${getters.selectedCertification.familyName}</strong>` +
            ' has been declined.'
        }
        commit('pushNotification', declineNotification)
        await router.push({ name: 'Certifications' })
      } catch (e) {
        commit('setLoading', false)
        //TODO: Once error handling is defined: Handle failed save or certify
      }
    },
    saveCertification({ dispatch, commit }) {
      if (!this.getters.isLoading) {
        commit('setLoading', true)
      }
      return dispatch('updateCertification')
        .then(() => {
          commit('pushNotification', {
            id: new Date(),
            attr: {
              timeout: 5000
            },
            icon: 'mdi-check',
            iconColor: 'green',
            message: '<strong>The checklist has been saved</strong>'
          })
        })
        .finally(() => {
          if (this.getters.isLoading) {
            commit('setLoading', false)
          }
        })
    },
    certifyCertification({ getters }) {
      return certificationsAPI.certifyCertification(
        getters.selectedCertification.registrationRecordNo,
        getters.selectedCertification.transactionNo,
        getters.selectedCertification.schoolCode
      )
    },
    withdrawCertification({ getters }) {
      return certificationsAPI.withdrawCertification(
        getters.selectedCertification.registrationRecordNo,
        getters.selectedCertification.transactionNo,
        getters.selectedCertification.schoolCode
      )
    },
    updateCertification({ getters, commit }, apiCode) {
      return certificationsAPI
        .updateCertification(getters.selectedCertification, apiCode)
        .then(
          // Reset the originalSelectCertification to the new updated value.
          (response) =>
            commit(
              'setInitialSelectedCertification',
              response.data.principalCertification
            )
        )
    },
    fetchErnStudentInfoBySRN({ commit }, { srn, schoolCode }) {
      return ernStudentInfoAPI
        .fetchErnStudentInfoBySRN(srn, schoolCode)
        .then((response) => {
          if (response) {
            commit('setSelectedErnStudentInfo', response.data)
          }
        })
    }
  }
}

export default certificationsModule
