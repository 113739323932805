<template>
  <div
    v-if="certificationLoaded"
    v-touch="{
      right: goToPrevCertification,
      left: goToNextCertification
    }"
  >
    <v-container fluid>
      <v-row no-gutters>
        <v-col class="d-none d-md-flex">
          <Banner>
            <template #content>
              <v-row>
                <v-col cols="1" class="d-flex">
                  <v-icon size="48" class="ml-auto align-self-start">
                    person_outline
                  </v-icon>
                </v-col>
                <v-col cols="11">
                  <h2 class="display-1">
                    {{ studentName }}
                    <StatusChip class="ml-5" :status="status" />
                  </h2>

                  <div class="mt-2 d-flex">
                    <span class="mr-10">
                      <h3>SRN:</h3>
                      <p>{{ studentSrn }}</p>
                    </span>
                    <span class="mr-10">
                      <h3>Start date:</h3>
                      <p>
                        {{ startDate }}
                      </p>
                    </span>
                    <span class="mr-10">
                      <h3>Scholastic year:</h3>
                      <p>{{ scholasticYear }}</p>
                    </span>
                    <span class="mr-10">
                      <h3>Date of birth:</h3>
                      <p>{{ dateOfBirth }}</p>
                    </span>
                    <span class="mr-10">
                      <h3>Gender:</h3>
                      <p>{{ gender }}</p>
                    </span>
                    <PaginationNav
                      v-if="totalPagesNum"
                      class="ml-auto mr-15"
                      :current-page="currentPageNum"
                      :total-pages="totalPagesNum"
                      @back="goToPrevCertification"
                      @forward="goToNextCertification"
                    />
                  </div>
                </v-col>
              </v-row>
            </template>
          </Banner>
        </v-col>
        <!-- Mobile/tablet header -->
        <v-col class="d-flex flex-column align-center d-md-none pa-2">
          <router-link
            to="/"
            tag="button"
            class="unstyled primary--text mt-8 mb-4 back-to-list--mobile"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </router-link>
          <span class="title pl-5 pr-5 mt-9 mb-2">
            {{ studentName }}
          </span>
          <span>
            SRN: <strong>{{ studentSrn }}</strong>
          </span>
          <span>
            Start date: <strong>{{ startDate }}</strong>
          </span>
          <span>
            Date of birth: <strong>{{ dateOfBirth }}</strong>
          </span>
          <span>
            Gender: <strong>{{ gender }}</strong>
          </span>
          <span>
            {{ scholasticYear }}
          </span>
          <StatusChip class="mt-2" :status="status" />
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <div class="inline-alert__container">
        <slot name="inline-alert" />
      </div>
      <v-row>
        <v-card
          :class="[
            'pr-6',
            'pl-6',
            'mr-md-0',
            'ml-md-0',
            { mobileFlags: $vuetify.breakpoint.xsOnly }
          ]"
          tile
        >
          <Flags :items="selectedCertification.flags || []" class="pb-0" />
        </v-card>
        <v-col class="pt-0 pl-0">
          <TabNavigation :items="items" data-testid="certification-tab">
            <template slot="content1">
              <v-row>
                <v-col class="pl-7">
                  <router-link
                    to="/"
                    tag="button"
                    class="unstyled primary--text mt-3"
                  >
                    <v-icon color="grey" size="30"> mdi-chevron-left </v-icon>
                    <span>Back</span>
                  </router-link>
                </v-col>
              </v-row>
              <v-col class="pl-15 pr-15 pt-0">
                <CertificationActions
                  v-if="$vuetify.breakpoint.mdAndUp"
                  @save="saveCertification"
                  @accept="attemptAcceptCertification"
                  @decline="attemptDeclineCertification"
                  data-testid="cert-actions"
                  :curr-status="status"
                />
                <TopSlideTransition aria-live="assertive">
                  <Alert
                    v-if="isSelectedCertificationValid === false"
                    :role="validationAlertRole"
                    data-testid="not-complete-warning"
                    type="error"
                    text="Checklist is not complete"
                    subtext="Please ensure all highlighted questions have been completed, then try again."
                    in-page
                    show-alert
                  />
                </TopSlideTransition>
                <Checklist ref="checklist" />
                <CertificationActions
                  @save="saveCertification"
                  @accept="attemptAcceptCertification"
                  @decline="attemptDeclineCertification"
                  data-testid="cert-actions"
                  :curr-status="status"
                />
              </v-col>
            </template>
            <template slot="content2">
              <v-row>
                <v-col class="pl-7">
                  <router-link
                    to="/"
                    tag="button"
                    class="unstyled primary--text mt-3"
                  >
                    <v-icon color="grey" size="30"> mdi-chevron-left </v-icon>
                    <span>Back</span>
                  </router-link>
                </v-col>
              </v-row>
              <FullApplication :srn="studentSrn" :school-code="schoolCode" />
            </template>
          </TabNavigation>
        </v-col>
      </v-row>
    </v-container>
    <AcceptCertificationModal
      v-model="showAcceptCertificationModal"
      :school-name="schoolName"
      @confirm="acceptCertification"
      data-testid="accept-cert-modal"
    />
    <DeclineCertificationModal
      v-model="showDeclineCertificationModal"
      :school-name="schoolName"
      @confirm="declineCertification"
      data-testid="decline-cert-modal"
    />
  </div>
  <div v-else-if="!$store.getters.isLoading">
    <v-container>
      <v-row>
        <v-col>
          <ErrorComponent />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { Alert, Banner, TabNavigation } from '@nswdoe/doe-ui-core'
import RadioGroup from '@/components/form/RadioGroup'
import Checkbox from '@/components/form/Checkbox'
import CertificationActions from '@/components/form/CertificationActions'
import Checklist from '@/components/form/Checklist'
import TopSlideTransition from '@/components/TopSlideTransition'
import AcceptCertificationModal from '@/components/AcceptCertificationModal'
import DeclineCertificationModal from '@/components/DeclineCertificationModal'
import Flags from '@/components/Flags'
import PaginationNav from '@/components/PaginationNav'
import StatusChip from '@/components/StatusChip'
import FullApplication from '@/views/FullApplication'
import ErrorComponent from '@/components/ErrorComponent'

import {
  YES_NO,
  YES_NOT_REQUIRED,
  SCHOLASTIC_YEARS,
  ERROR_CODES
} from '@/constants'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { displayDateFormat } from '@/utils/general'

const Certification = {
  name: 'Certification',
  components: {
    AcceptCertificationModal,
    Alert,
    Banner,
    RadioGroup,
    Checkbox,
    CertificationActions,
    Checklist,
    TopSlideTransition,
    DeclineCertificationModal,
    Flags,
    PaginationNav,
    StatusChip,
    TabNavigation,
    FullApplication,
    ErrorComponent
  },
  props: {
    certifications: {
      type: Array,
      default: () => []
    }
  },
  async created() {
    if (!this.schools?.length) {
      await this.$store.dispatch('fetchSchools')
    }

    if (this.isValidSchoolCode) {
      this.fetchCertificationById({
        id: this.certificationId,
        schoolCode: this.schoolCode
      })
      this.$store.dispatch('setCurrentUser')
      this.$store.dispatch('setCurrentSchool', this.schoolCode)
    } else {
      this.$store.dispatch('setError', {
        title: "Sorry, you don't have access to this school.",
        text: 'Please select a valid school on the list of certifications.',
        type: ERROR_CODES.USER_ERROR
      })
    }
  },
  beforeDestroy() {
    this.clearSelectedCertification()
  },
  data: function () {
    return {
      YES_NO,
      YES_NOT_REQUIRED,
      SCHOLASTIC_YEARS,
      selectedItem: null,
      displayRiskAssessmentRequired: null,
      displayRiskAssessmentConducted: null,
      showAcceptCertificationModal: false,
      showDeclineCertificationModal: false,
      validationAlertRole: null,
      items: [
        {
          tab: 'CHECKLIST'
        },
        {
          tab: 'FULL APPLICATION'
        }
      ]
    }
  },

  computed: {
    ...mapGetters([
      'selectedCertification',
      'isSelectedCertificationValid',
      'currentSchool',
      'isPrincipal',
      'schools',
      'schoolsForUser'
    ]),
    certificationId() {
      return this.$route.query.id
    },
    schoolCode() {
      return this.$route.query.schoolCode
    },
    studentName() {
      return (
        this.selectedCertification &&
        this.selectedCertification.firstName +
          ' ' +
          this.selectedCertification.familyName
      )
    },
    studentSrn() {
      return this.selectedCertification?.srn
    },

    startDate() {
      return displayDateFormat(this.selectedCertification?.intendedStartDate)
    },
    scholasticYear() {
      return SCHOLASTIC_YEARS[this.selectedCertification?.scholasticYear]
    },
    dateOfBirth() {
      return displayDateFormat(this.selectedCertification?.dateOfBirth)
    },
    gender() {
      return this.selectedCertification?.gender
    },
    status() {
      return this.selectedCertification?.status
    },
    schoolName() {
      return this.schools?.find(
        ({ schoolCode }) => schoolCode === this.selectedCertification.schoolCode
      )?.schoolName
    },
    certificationLoaded() {
      return (
        this.selectedCertification &&
        Object.keys(this.selectedCertification).length > 0
      )
    },
    currentPageNum() {
      const index = this.certifications?.findIndex(
        ({ registrationRecordNo }) =>
          registrationRecordNo ===
          this.selectedCertification.registrationRecordNo
      )
      return Number.isInteger(index) ? index + 1 : null
    },
    totalPagesNum() {
      return this.certifications.length
    },
    isValidSchoolCode() {
      return this.schoolsForUser
        .map((s) => s.schoolCode)
        .includes(this.schoolCode)
    }
  },
  methods: {
    ...mapActions([
      'fetchCertificationById',
      'updateHiddenFieldValues',
      'acceptCertification',
      'declineCertification',
      'fetchErnStudentInfoBySRN'
    ]),
    ...mapMutations([
      'setSelectedCertificationValid',
      'clearSelectedCertification'
    ]),
    validate() {
      const isValid = this.$refs.checklist.validate()
      this.setSelectedCertificationValid(isValid)
      this.triggerAccessibilityValidationAlert()
      return isValid
    },
    saveCertification() {
      if (this.$refs.checklist.isNotesValid()) {
        this.updateHiddenFieldValues()
        this.$store.dispatch('saveCertification')
      }
      this.validate()
    },
    async attemptAcceptCertification() {
      await this.updateHiddenFieldValues()
      if (this.validate()) {
        this.showAcceptCertificationModal = true
      }
    },
    async attemptDeclineCertification() {
      await this.updateHiddenFieldValues()
      if (this.validate()) {
        this.showDeclineCertificationModal = true
      }
    },
    triggerAccessibilityValidationAlert() {
      //Temporarily add the 'alert' role so that it is read by the screen reader on validation failure
      this.validationAlertRole = 'alert'
      setTimeout(() => (this.validationAlertRole = null), 1000)
    },
    goToPrevCertification() {
      const newCertID =
        this.certifications?.[this.currentPageNum - 2]?.registrationRecordNo
      this.goToNewCert(newCertID, this.currentSchool.schoolCode)
    },
    goToNextCertification() {
      const newCertID =
        this.certifications?.[this.currentPageNum]?.registrationRecordNo
      this.goToNewCert(newCertID, this.currentSchool.schoolCode)
    },
    goToNewCert(id, schoolCode) {
      if (id) {
        this.$router.push({
          name: 'Certification',
          query: { id, schoolCode },
          params: { certifications: this.certifications }
        })
      }
    }
  }
}

export default Certification
</script>

<style lang="scss" scoped>
.banner {
  margin-top: 0px;
  background-image: url(../assets/bg-banner@3x.png);
  background-position: right top;
  background-size: auto 218px;
  background-repeat: no-repeat;
  background-color: $ads-navy;
  color: $ads-white;
}

.divider {
  border-top: 0;
}

.flags {
  margin-top: 10px;

  & ::v-deep .v-list-item__content {
    color: $color-primary;
  }
}

.back-to-list--mobile {
  position: absolute;
  left: 20px;
}
.title {
  width: 90%;
  word-wrap: break-word;
  text-align: center;
}

.inline-alert__container ::v-deep .alert--element {
  margin-top: 20px;
}

::v-deep .v-tab {
  padding: 0 50px;
}

::v-deep .v-tab span {
  color: $color-primary;
}

.mobileFlags {
  margin: 20px auto;
}
</style>
