<template>
  <v-card class="checklist mx-auto pa-6 mb-4" tile>
    <div class="d-flex flex-column flex-md-row">
      <h3 class="headline">Principal checklist</h3>
    </div>

    <v-form lazy-validation ref="certificationForm">
      <TopSlideTransition group>
        <RadioGroup
          key="enrolmentInterviewConducted"
          id="enrolmentInterviewConducted"
          class="checklist__question"
          label="Enrolment interview conducted?"
          :items="YES_NO"
          :rules="[requiredYesNo]"
          row
          v-model="enrolmentInterviewConducted"
          :disabled="certificationShouldBeDisabled"
        />

        <RadioGroup
          key="circumstancesHistoryAssessed"
          id="circumstancesHistoryAssessed"
          class="checklist__question"
          label="Special circumstances, additional support needs and student history assessed?"
          :items="YES_NOT_REQUIRED"
          :rules="[requiredYesNotRequired]"
          row
          v-model="circumstancesHistoryAssessed"
          :disabled="certificationShouldBeDisabled"
        />

        <v-card
          v-if="isMismatchSpecialCircumstance"
          key="flag-special"
          class="flag-special mb-6 mt-0"
          elevation="0"
        >
          <v-icon size="30" class="flag-special__icon"> mdi-alert </v-icon>
          <p class="flag-special__text mt-1">
            <strong>NOTE:</strong> This conflicts with the ERN record for this
            student
          </p>
        </v-card>

        <RadioGroup
          v-if="!hiddenFields['riskAssessmentRequired']"
          key="riskAssessmentRequired"
          id="riskAssessmentRequired"
          class="checklist__question"
          label="Risk assessment required?"
          :items="YES_NO"
          :rules="[requiredYesNo]"
          row
          v-model="riskAssessmentRequired"
          :disabled="certificationShouldBeDisabled"
        />

        <Checkbox
          v-if="!hiddenFields['riskAssessmentConducted']"
          v-model="riskAssessmentConducted"
          key="riskAssessmentConducted"
          id="riskAssessmentConducted"
          class="checklist__question"
          label="Yes"
          legend="Risk assessment conducted?"
          yes-no-values
          :rules="[requiredYes]"
          :disabled="certificationShouldBeDisabled"
        />

        <hr key="divider-1" class="mr-n6 ml-n6 grey lighten-4 divider" />

        <RadioGroup
          v-if="!hiddenFields['personalisedLearningSupport']"
          key="personalisedLearningSupport"
          id="personalisedLearningSupport"
          class="checklist__question"
          label="Is personalised learning and support required for this student?"
          :items="YES_NO"
          :rules="[requiredYesNo]"
          row
          v-model="personalisedLearningSupport"
          :disabled="certificationShouldBeDisabled"
        />

        <Checkbox
          v-if="!hiddenFields['consultWithParentsConducted']"
          yes-no-values
          key="consultWithParentsConducted"
          v-model="consultWithParentsConducted"
          id="consultWithParentsConducted"
          :rules="[requiredYes]"
          class="checklist__question"
          label="Yes"
          legend="Consultation with parents/carers conducted?"
          :disabled="certificationShouldBeDisabled"
        />

        <TopSlideTransition
          key="planningQuestions"
          tag="section"
          element-max-height="40rem"
        >
          <v-card
            v-if="!hidePlanningQuestions"
            class="planning-card py-8 px-6 grey lighten-7"
            data-testid="planning-card"
            :elevation="0"
            role="group"
            aria-labelledby="planning-card-title"
          >
            <h4
              id="planning-card-title"
              class="planning-card__title primary--text"
            >
              Please select “Yes” from at least one of the questions below:
            </h4>
            <RadioGroup
              key="planningPersonalisedLearningSupport"
              id="planningPersonalisedLearningSupport"
              class="checklist__question"
              label="Planning to personalise learning and support completed?"
              :items="YES_NOT_REQUIRED"
              :rules="[requiredYesNotRequired]"
              row
              v-model="planningPersonalisedLearningSupport"
              :disabled="certificationShouldBeDisabled"
            />

            <RadioGroup
              key="behaviourPlanViolenceDeveloped"
              id="behaviourPlanViolenceDeveloped"
              aria-describedby="violence-flag-container"
              class="checklist__question"
              label="Behaviour Management Plan (violence) developed?*"
              :items="YES_NOT_REQUIRED"
              :rules="[requiredYesNotRequired]"
              row
              v-model="behaviourPlanViolenceDeveloped"
              :disabled="certificationShouldBeDisabled"
            />
            <TopSlideTransition id="violence-flag-container">
              <v-card
                v-if="showViolenceFlagWarning"
                key="flag-note"
                class="flag-note mb-6 grey lighten-7"
                data-testid="violence-flag-warning"
                elevation="0"
              >
                <v-icon size="30" class="flag-note__icon"> $redFlag </v-icon>
                <p class="flag-note__text">
                  <strong>NOTE:</strong> Selecting <strong>Yes</strong> to
                  <em>Behaviour Management Plan (violence) developed</em>
                  will raise a red flag against this student record in ERN.
                </p>
              </v-card>
            </TopSlideTransition>
            <RadioGroup
              key="behaviourPlanOtherDeveloped"
              id="behaviourPlanOtherDeveloped"
              class="checklist__question"
              label="Behaviour Management Plan (other) developed?*"
              :items="YES_NOT_REQUIRED"
              :rules="[requiredYesNotRequired]"
              row
              v-model="behaviourPlanOtherDeveloped"
              :disabled="certificationShouldBeDisabled"
            />

            <RadioGroup
              key="healthCarePlanDeveloped"
              id="healthCarePlanDeveloped"
              class="checklist__question"
              label="Individual Health Care Plan developed?*"
              :items="YES_NOT_REQUIRED"
              :rules="[requiredYesNotRequired]"
              row
              v-model="healthCarePlanDeveloped"
              :disabled="certificationShouldBeDisabled"
            />

            <RadioGroup
              key="emergencyRespPlanDeveloped"
              id="emergencyRespPlanDeveloped"
              class="checklist__question"
              label="Emergency response plan developed?**"
              :items="YES_NOT_REQUIRED"
              :rules="[requiredYesNotRequired]"
              row
              v-model="emergencyRespPlanDeveloped"
              :disabled="certificationShouldBeDisabled"
            />

            <TopSlideTransition>
              <InputErrorMessage
                v-if="showEmergencyPlanWarning"
                class="error-message--alert"
                message="An emergency response plan requires an individual health care plan to be developed"
              />
            </TopSlideTransition>

            <TopSlideTransition element-max-height="5rem">
              <InputErrorMessage
                v-if="hasValidationBeenTriggered && !somePlanningSelected"
                id="planning-error-message"
              >
                Please ensure you can indicate <strong>“Yes”</strong> for at
                least one of the questions above
              </InputErrorMessage>
            </TopSlideTransition>
          </v-card>
        </TopSlideTransition>

        <RadioGroup
          key="commDocumentedProvisions"
          id="commDocumentedProvisions"
          class="checklist__question"
          label="Communication of documented provision/s and plan/s to relevant staff?"
          :items="YES_NOT_REQUIRED"
          :rules="[requiredYesNotRequired]"
          row
          v-model="commDocumentedProvisions"
          :disabled="certificationShouldBeDisabled"
        />
      </TopSlideTransition>
      <v-textarea
        key="enrolmentNotes"
        id="enrolmentNotes"
        label="Enrolment notes (optional)"
        placeholder="Maximum 2048 characters"
        :rules="[max(2048), validCharacters]"
        v-model="enrolmentNotes"
        outlined
        counter="2048"
        :disabled="certificationShouldBeDisabled"
        ref="notes"
        persistent-placeholder
      >
        <template #message="{ message }">
          <InputErrorMessage
            :message="message"
            data-testid="enrolmentNotesError"
          />
        </template>
      </v-textarea>
    </v-form>

    <div class="font-italic grey--text text--lighten-2 mt-6">
      <p>
        * It may be necessary to defer the finalisation of enrolment until this
        action has been taken. This may require development of an interim plan
        until all relevant medical or other information has been obtained.
        Consideration must be given to all special needs when developing
        behaviour management or health care plans. Any deferral should be no
        more than reasonably necessary to collect the required information. An
        emergency response plan must be included in the student’s individual
        health care plan where the student is diagnosed at risk of a medical
        emergency.
      </p>
      <p>
        ** Where a student has been diagnosed at risk of anaphylaxis the
        emergency response plan will be the ASCIA Action Plan for Anaphylaxis,
        which will be provided by the parent, completed and signed by the
        treating doctor.
      </p>
    </div>
  </v-card>
</template>

<script>
import RadioGroup from '@/components/form/RadioGroup'
import TopSlideTransition from '@/components/TopSlideTransition'
import Checkbox from '@/components/form/Checkbox'
import {
  YES_NO,
  YES_NOT_REQUIRED,
  SCHOLASTIC_YEARS,
  RADIO_OPTIONS
} from '@/constants'
import { mapGetters } from 'vuex'
import {
  isYesValue,
  isValidYesNo,
  isValidYesNotRequired
} from '@/utils/validation'
import { sanitiseStringForErn } from '@/utils/general'
import InputErrorMessage from '@/components/form/InputErrorMessage'

const REQUIRED_ERROR_TEXT = 'This response is required'

const mapCertificationGettersSetters = (variables) =>
  variables.reduce(
    (result, variable) => ({
      ...result,
      [variable]: {
        get() {
          return this.$store.getters.selectedCertification?.[variable]
        },
        set(value) {
          this.$store.commit('updateCertification', { [variable]: value })
        }
      }
    }),
    {}
  )

export default {
  name: 'FormChecklist',
  components: {
    InputErrorMessage,
    RadioGroup,
    Checkbox,
    TopSlideTransition
  },
  data: function () {
    return {
      YES_NO,
      YES_NOT_REQUIRED,
      RADIO_OPTIONS,
      SCHOLASTIC_YEARS,
      selectedItem: null,
      displayRiskAssessmentRequired: null,
      displayRiskAssessmentConducted: null,
      hasValidationBeenTriggered: false
    }
  },
  computed: {
    ...mapGetters([
      'selectedCertification',
      'hiddenFields',
      'hidePlanningQuestions',
      'certificationShouldBeDisabled'
    ]),
    ...mapCertificationGettersSetters([
      'enrolmentInterviewConducted',
      'circumstancesHistoryAssessed',
      'riskAssessmentRequired',
      'riskAssessmentConducted',
      'personalisedLearningSupport',
      'consultWithParentsConducted',
      'planningPersonalisedLearningSupport',
      'behaviourPlanViolenceDeveloped',
      'behaviourPlanOtherDeveloped',
      'healthCarePlanDeveloped',
      'emergencyRespPlanDeveloped',
      'commDocumentedProvisions'
    ]),
    enrolmentNotes: {
      get() {
        return this.$store.getters.selectedCertification?.enrolmentNotes
      },
      set(value) {
        // ERN hates pretty quotes
        this.$store.commit('updateCertification', {
          enrolmentNotes: sanitiseStringForErn(value)
        })
      }
    },
    showViolenceFlagWarning() {
      return (
        !this.hiddenFields['behaviourPlanViolenceDeveloped'] &&
        this.behaviourPlanViolenceDeveloped === RADIO_OPTIONS.YES.value
      )
    },
    showEmergencyPlanWarning() {
      return (
        !this.hiddenFields['emergencyRespPlanDeveloped'] &&
        this.healthCarePlanDeveloped === RADIO_OPTIONS.NOT_REQUIRED.value &&
        this.emergencyRespPlanDeveloped === RADIO_OPTIONS.YES.value
      )
    },
    somePlanningSelected() {
      return [
        this.planningPersonalisedLearningSupport,
        this.behaviourPlanViolenceDeveloped,
        this.behaviourPlanOtherDeveloped,
        this.healthCarePlanDeveloped,
        this.emergencyRespPlanDeveloped
      ].some((value) => value === RADIO_OPTIONS.YES.value)
    },
    isMismatchSpecialCircumstance() {
      const { specialCircumstanceInd } =
        this.$store.getters.selectedCertification
      return (
        specialCircumstanceInd === 'Y' &&
        this.circumstancesHistoryAssessed === 'N'
      )
    }
  },
  watch: {
    riskAssessmentRequired: {
      immediate: true,
      handler(newValue) {
        if (newValue === RADIO_OPTIONS.NO.value) {
          this.riskAssessmentConducted = RADIO_OPTIONS.NO.value
        }
      }
    },
    personalisedLearningSupport: {
      immediate: true,
      handler(newValue) {
        if (newValue === RADIO_OPTIONS.NO.value) {
          this.consultWithParentsConducted = RADIO_OPTIONS.NO.value
        }
      }
    }
  },
  methods: {
    validate() {
      this.hasValidationBeenTriggered = true
      return (
        this.$refs.certificationForm.validate() &&
        (this.hidePlanningQuestions || this.somePlanningSelected)
      )
    },
    isNotesValid() {
      // called externally
      return this.$refs.notes.valid
    },
    requiredYesNo(value) {
      return isValidYesNo(value) || REQUIRED_ERROR_TEXT
    },
    requiredYesNotRequired(value) {
      return isValidYesNotRequired(value) || REQUIRED_ERROR_TEXT
    },
    requiredYes(value) {
      return isYesValue(value) || REQUIRED_ERROR_TEXT
    },
    max(num) {
      return (v) => {
        if (v) {
          return v.length <= num || `Maximum ${num} characters`
        } else {
          return true
        }
      }
    },
    validCharacters(str) {
      const validationRegex =
        /^[ \t\r\nA-Za-z0-9,./\\!~`@#$%^&*()\-_—+={}[\]|;:'"<>?]*$/
      return (
        !str ||
        validationRegex.test(str) ||
        'Invalid character(s) - avoid copy/pasting text from Word or Outlook'
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.divider {
  border-top: 0;
}

.checklist__question {
  margin-top: 1rem;

  ::v-deep .v-label {
    color: $color-primary;
  }
}

.planning-card {
  &__title {
    font-size: 1rem;
  }
}

.flag-note {
  display: flex;
  width: 38rem;

  &__icon {
    margin-right: 1rem;
  }

  &__text {
    margin-bottom: 0;
  }
}

.flag-special {
  display: flex;
  width: 38rem;

  &__icon {
    margin-right: 1rem;
    color: $ads-warning-orange;
  }

  &__text {
    margin-bottom: 0;
  }
}

.error-message.error-message--alert {
  &,
  ::v-deep * {
    color: $color-primary !important;
  }
}

.checklist {
  box-shadow: none !important;
}
</style>
