<template>
  <CloseableDialog v-bind="$attrs" v-on="$listeners">
    <template #title> Decline this student for enrolment? </template>
    <template #text>
      The status of the application will be set to “<strong>Declined</strong>”.
      <br />
      <br />
      This student will not be offered a place at {{ schoolName }}.
    </template>
    <template #actions>
      <v-btn class="red white--text" @click="confirm">
        <v-icon class="mr-2"> mdi-cancel </v-icon>
        Confirm decline student
      </v-btn>
    </template>
  </CloseableDialog>
</template>

<script>
import CloseableDialog from './CloseableDialog.vue'

export default {
  name: 'DeclineCertificationModal',
  components: { CloseableDialog },
  props: {
    schoolName: {
      type: String,
      default: ''
    }
  },
  methods: {
    confirm() {
      this.$emit('confirm')
      this.$emit('input', false)
    }
  }
}
</script>

<style scoped></style>
