import jwtDecode from 'jwt-decode'
import authCrypto from './authCrypto'
import utils from './utils'
import Axios from 'axios'

function isRedirectFromImplicitFlow() {
  return utils.getHashParams().access_token
}

function isRedirectFromAuthCodeFlow() {
  return utils.getQueryParams().code
}

// Identity Provider Specific Functions
function generateAuthUrl({ urlBase, params }) {
  return `${urlBase}?${utils.queryStringify(params)}`
}

async function handleImplicitRedirect(
  authPersist,
  onAuthorisedRedirect,
  onRedirectError,
  next,
  goToRouteOnRedirect
) {
  try {
    const { id_token, access_token } = utils.getHashParams()
    const idDecoded = jwtDecode(id_token)
    const persistedState = authPersist.getAuthValues()

    authPersist.removeAuthValues()

    const isValidNonce = await authCrypto.verifyHash(
      persistedState.secret,
      idDecoded.nonce
    )

    if (isValidNonce && onAuthorisedRedirect) {
      onAuthorisedRedirect(
        { id: id_token, access: access_token },
        persistedState
      )
      goToRouteOnRedirect ? next(persistedState.path) : next()
    }
  } catch (e) {
    authPersist.removeAuthValues()
    if (onRedirectError) {
      onRedirectError(e)
    }
  }
}

async function handleAuthCodeRedirect(
  authPersist,
  onAuthorisedRedirect,
  onRedirectError,
  next,
  authProviderConfig,
  goToRouteOnRedirect
) {
  try {
    const persistedState = authPersist.getAuthValues()
    const authProvider = authProviderConfig[persistedState.authProvider]
    const data = utils.queryStringify({
      grant_type: 'authorization_code',
      client_id: authProvider.params.client_id,
      code: persistedState.code,
      code_verifier: persistedState.secret,
      redirect_uri: `${location.origin}/`
    })

    authPersist.removeAuthValues()

    const authResponse = await Axios.post(authProvider.urls.token, data, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })

    let { id_token, refresh_token, access_token } = authResponse.data

    if (onAuthorisedRedirect) {
      onAuthorisedRedirect(
        { id: id_token, refresh: refresh_token, access: access_token },
        persistedState
      )
    }
    goToRouteOnRedirect ? next(persistedState.path) : next()
  } catch (e) {
    authPersist.removeAuthValues()
    if (onRedirectError) {
      onRedirectError(e)
    }
  }
}

function createAuthRouteInterceptor(
  authPersist,
  onAuthorisedRedirect,
  onRedirectError,
  authProviderConfig,
  goToRouteOnRedirect
) {
  return (to, from, next) => {
    if (isRedirectFromImplicitFlow()) {
      handleImplicitRedirect(
        authPersist,
        onAuthorisedRedirect,
        onRedirectError,
        next,
        goToRouteOnRedirect
      )
    } else if (isRedirectFromAuthCodeFlow()) {
      /*
      This is required as the hash routing fails when we redirect from the auth code flow with a query string.
      So we store the code and refresh the page to the base url.
      */

      const { code } = utils.getQueryParams()
      authPersist._persist('code', code)
      window.location.href = `${location.origin}/`
      return
    } else if (authPersist._get('code')) {
      handleAuthCodeRedirect(
        authPersist,
        onAuthorisedRedirect,
        onRedirectError,
        next,
        authProviderConfig,
        goToRouteOnRedirect
      )
      return
    }
    authPersist.removeAuthValues()

    next()
  }
}

export { createAuthRouteInterceptor, generateAuthUrl }
