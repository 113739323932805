<template>
  <v-dialog
    v-model="valueInternal"
    fullscreen
    persistent
    no-click-animation
    hide-overlay
    transition="dialog-bottom-transition"
    origin="center bottom"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        dark
        class="mr-3 settingsIcon circular-btn"
        aria-label="Settings"
        v-bind="attrs"
        v-on="on"
        data-testid="app-settings-cog"
        @click="settingsCogClicked"
        ref="settingsCog"
      >
        <v-icon medium> mdi-cog-outline </v-icon>
      </v-btn>
    </template>
    <v-card rounded="0">
      <div tabindex="0" ref="appSettingsModalPreFocus" />
      <div
        class="d-flex align-center app-settings__header"
        :aria-hidden="displayChangedModal || displaySaveCloseModal"
      >
        <span
          id="closeButtonLabel"
          class="d-sr-only"
          aria-label="Close enrolment settings"
        />
        <v-btn
          icon
          text
          class="white--text mr-3"
          @click="close"
          aria-labelledby="closeButtonLabel"
          data-testid="app-settings-modal-close"
          ref="closeWithoutSave"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <h1>Principal Enrolment Interface Settings</h1>
        <v-spacer />
        <AdsButton
          class="app-settings__header-save-button mr-9 primary--text"
          v-show="isDataChanged"
          color="tertiary"
          @click="save"
          data-testid="app-settings-modal-save"
          icon="save_alt"
          button-text="Save &amp; Close"
        />
      </div>
      <div
        class="app-settings__content pb-13"
        v-if="!loaded"
        :aria-hidden="displayChangedModal || displaySaveCloseModal"
      >
        <v-container>
          <AppDialog
            title="Close settings without saving?"
            max-width="550px"
            :open-dialog="displayChangedModal"
            display-close-btn
            message="Select <b>Discard Changes</b> to close this window without saving.<br/>To save your changes, select <b>Cancel</b>, then select the <b>Save &amp; Close</b> button."
            :actions="displayChangedActionList"
            @close="displayChangedModal = false"
            :return-handler="handleCloseWithoutSaving"
            data-testid="close-without-saving-dialog"
            id="close-without-saving"
          />
        </v-container>

        <div class="d-flex align-start pa-6 pa-md-12">
          <div
            class="app-settings__circle-icon d-flex align-center justify-center"
          >
            <v-icon size="30"> mdi-cog-outline </v-icon>
          </div>
          <div class="ml-3">
            <h2 data-testid="app-settings-heading" tabindex="0">
              {{ school.schoolName }} Principal Enrolment Interface Settings
            </h2>
            <p class="subtitle mt-2">
              Adjust your settings below. Select
              <strong>Save &amp; Close</strong> to save changes.
            </p>
          </div>

          <v-spacer />
          <div
            class="d-flex align-center app-settings__last-updated"
            v-if="lastUpdatedBy && lastUpdatedDate"
          >
            <v-icon>mdi-history</v-icon>
            <p class="mb-0 ml-1" data-testid="app-settings-lastupdated-text">
              Last updated by: {{ lastUpdatedBy }}
              {{ formattedLastUpdatedDate }}
            </p>
          </div>
        </div>

        <TabNavigation
          class="pr-12"
          v-model="settingsTab"
          :vertical="true"
          :items="getSettingsTabItems"
          text-transform="none"
          :hide-slider="true"
          data-testid="settings-tab"
        >
          <template slot="content1">
            <v-card class="ml-6">
              <AppSettingsEmailPreferences
                :email-setting="emailSetting"
                @updatedEmailSetting="setEmailSetting"
              />
            </v-card>
          </template>
        </TabNavigation>

        <div class="full-width pa-6 pa-md-12">
          <AdsButton
            v-show="isDataChanged"
            class="app-settings__save-button settingsPrimary"
            @click="save"
            data-testid="app-settings-modal-save"
            icon="save_alt"
            button-text="Save &amp; Close"
          />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { AdsButton, Dialog } from '@nswdoe/doe-ui-core'
import TabNavigation from './TabNavigation.vue'
import AppSettingsEmailPreferences from './AppSettingsEmailPreferences.vue'
import { DISCARD_MODAL_OPTIONS } from '@/constants'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'AppSettingsModal',
  components: {
    AdsButton,
    TabNavigation,
    AppDialog: Dialog,
    AppSettingsEmailPreferences
  },
  props: {
    school: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loaded: false,
      valueInternal: false,
      displayChangedModal: false,
      displaySaveCloseModal: false,
      settingsTab: null,
      settingsTabItems: [{ tab: 'Email preferences', icon: 'mail_outline' }],
      displayChangedActionList: [
        {
          name: DISCARD_MODAL_OPTIONS.CANCEL,
          color: 'primary',
          size: 'large',
          outlined: true
        },
        {
          name: DISCARD_MODAL_OPTIONS.DISCARD_CHANGES,
          color: 'primary',
          size: 'large',
          outlined: false
        }
      ],
      emailSetting: true,
      originalData: null,
      lastUpdatedBy: null,
      lastUpdatedDate: null
    }
  },
  computed: {
    ...mapGetters(['customisationSetting']),
    getSettingsTabItems() {
      return [this.settingsTabItems[0]]
    },
    isDataChanged() {
      return this.haveAnySettingsChanged()
    },
    formattedLastUpdatedDate() {
      const isoDateString = this.lastUpdatedDate
      return this.getFormattedDate(isoDateString)
    }
  },
  methods: {
    settingsCogClicked() {
      this.$emit('opened', true)
    },
    haveAnySettingsChanged() {
      let isChanged = false
      if (this.originalData) {
        const originalEmailSetting = this.originalData?.pei?.emailPreference
        isChanged = !_.isEqual(this.emailSetting, originalEmailSetting)
      }
      return isChanged
    },
    close() {
      if (this.haveAnySettingsChanged()) {
        this.displayChangedModal = true
      } else {
        this.closeAppSettingsModal()
      }
    },
    async closeAppSettingsModal() {
      await this.$store.dispatch('clearCustomisationSettings')
      this.lastUpdatedBy = null
      this.lastUpdatedDate = null
      this.valueInternal = false
      this.$emit('opened', false)

      const cogButton = this.$refs.settingsCog.$el
      setTimeout(function () {
        cogButton.focus()
      }, 300)
    },
    handleCloseWithoutSaving(actionName) {
      this.displayChangedModal = false
      if (actionName === DISCARD_MODAL_OPTIONS.DISCARD_CHANGES) {
        this.closeAppSettingsModal()
      }
    },
    getCurrentEmailSetting(schoolCode, emailSetting) {
      return {
        schoolCode: schoolCode,
        pei: {
          emailPreference: emailSetting
        }
      }
    },
    getDefaultEmailSetting(schoolCode) {
      return {
        schoolCode: schoolCode,
        pei: {
          emailPreference: true
        }
      }
    },
    setEmailSetting(value) {
      this.emailSetting = value
    },
    async save() {
      const schoolCode = this.school?.schoolCode
      const updatedSettings = this.getCurrentEmailSetting(
        schoolCode,
        this.emailSetting
      )

      await this.$store.dispatch('saveCustomisationSettings', {
        schoolCode,
        settings: updatedSettings
      })
      this.displaySaveCloseModal = false
      await this.closeAppSettingsModal()
    },
    getFormattedDate(dateString) {
      if (dateString && dateString.length > 10) {
        const defaultDateString = String(dateString).slice(0, 10)
        const monthNames = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ]
        const lastUpdatedDate = new Date(defaultDateString)
        if (lastUpdatedDate) {
          const day = lastUpdatedDate.getDate()
          const monthIndex = lastUpdatedDate.getMonth()
          const monthName = monthNames[monthIndex]
          const year = lastUpdatedDate.getFullYear()
          return `${day} ${monthName} ${year}`
        }
      }
      return null
    },
    async loadSavedSettings() {
      await this.$store.dispatch(
        'getSavedCustomisationSettings',
        this.school.schoolCode
      )

      const customisationSetting = this.customisationSetting
      if (customisationSetting) {
        const settings = customisationSetting.customisation
        this.originalData = _.cloneDeep(settings)
        if (settings) {
          this.emailSetting = settings?.pei?.emailPreference
        }
        this.lastUpdatedBy = customisationSetting.lastUpdatedBy
        this.lastUpdatedDate = customisationSetting.lastUpdatedDate
      } else {
        // for school doesn't have email reference settings
        const schoolCode = this.school?.schoolCode
        const defaultEmailSetting = this.getDefaultEmailSetting(schoolCode)
        this.emailSetting = defaultEmailSetting.pei.emailPreference
        this.originalData = _.cloneDeep(defaultEmailSetting)
        this.lastUpdatedBy = null
        this.lastUpdatedDate = null
      }
      this.loaded = false
    },
    async init() {
      this.loaded = true
      await this.loadSavedSettings()
      this.$nextTick(function () {
        this.$refs.appSettingsModalPreFocus &&
          this.$refs.appSettingsModalPreFocus.focus()
      })
    }
  },
  watch: {
    valueInternal(newValue, oldValue) {
      if (oldValue === false && newValue === true) {
        this.init()
      }
    }
  }
}
</script>

<style lang="scss">
.app-settings__header {
  background: $ads-navy;
  color: white !important;
  position: sticky; // sticky allows keyboard scroll when focused in the header
  top: 0;
  left: 0;
  width: 100vw;
  height: 64px;
  z-index: 6;
  h1 {
    font-size: 1.25rem;
  }
  .v-btn {
    &:focus {
      border: 2px solid $color-focus !important;
    }
  }
}

.app-settings__content {
  color: $ads-navy;
  background: $ads-light-10;
  width: 100%;
  min-height: calc(100vh - 64px);
  position: absolute;
  top: 64px;
  h2 {
    font-size: 26px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 0;
  }
  p.subtitle {
    font-size: 16px;
    color: $ads-navy;
  }
  .app-settings__circle-icon {
    border-radius: 50%;
    background: $ads-light-blue;
    color: $ads-navy;
    min-width: 54px;
    height: 54px;
    &.large {
      min-width: 116px;
      height: 116px;
    }
  }
  .app-settings__last-updated {
    font-style: italic;
    color: $ads-dark-70;
    font-size: 14px;
    .v-icon {
      color: $ads-dark-70;
    }
  }
  .app-settings-heading {
    font-size: 20px;
    font-weight: 700;
    color: $ads-navy;
  }
  .app-settings__subheading {
    font-size: 16px;
    font-weight: 700;
    color: $ads-navy;
  }
  .app-settings__paragraph-text {
    max-width: 480px;
  }
  .full-width {
    width: 100%;
    height: 39px;
    button {
      float: right;
    }
  }
  .sr-only {
    background-color: transparent;
  }
  .v-tabs {
    .v-tab {
      font-size: 18px;
      margin-bottom: 20px;
      letter-spacing: normal;
      padding-left: 60px;
      width: 292px;
      height: 67px;

      span {
        z-index: 1;
      }
    }
    .v-tabs-bar,
    .v-tabs-items {
      background: $ads-light-10;
    }
    .v-tab--active {
      background: $ads-white;
    }
  }
}

.app-settings__header-save-button {
  background-color: $color-tertiary !important;
  border-color: $color-tertiary !important;
}
</style>
