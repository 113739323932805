import axios from 'axios'
import store from '@/store'
import { API_ERROR_SUBTYPES } from '@/constants'

const API_BASE = process.env.VUE_APP_ROOT_API

const CERTIFICATIONS_URL = `${API_BASE}/registrations/principalCertification`
const CERTIFICATION_URL = `${API_BASE}/registrations/principalCertification/details`
const CERTIFY_URL = `${API_BASE}/registration/certify`
const WITHDRAW_URL = `${API_BASE}/registration/withdraw`

function fetchCertifications(schoolCode) {
  return axios
    .get(`${CERTIFICATIONS_URL}/${schoolCode}`)
    .then((response) => response)
    .catch((error) => {
      store.dispatch('setAPIError', {
        error,
        fallbackCode: API_ERROR_SUBTYPES.listCertification
      })
      throw error
    })
}

function fetchCertificationById(certId, schoolCode) {
  return axios
    .get(`${CERTIFICATION_URL}/${schoolCode}/${certId}`)
    .then((response) => response)
    .catch((error) => {
      store.dispatch('setAPIError', {
        error,
        fallbackCode: API_ERROR_SUBTYPES.certificationDetails
      })
      throw error
    })
}

function certifyCertification(registrationRecordNo, transactionNo, schoolCode) {
  return axios
    .put(CERTIFY_URL, { registrationRecordNo, transactionNo, schoolCode })
    .catch((error) => {
      if (error.response.data.status === 409) {
        store.dispatch('setAPIError', {
          error,
          fallbackCode: API_ERROR_SUBTYPES.acceptCertificationConflict
        })
      } else {
        store.dispatch('setAPIError', {
          error,
          fallbackCode: API_ERROR_SUBTYPES.acceptCertification
        })
      }
      throw error
    })
}

function withdrawCertification(
  registrationRecordNo,
  transactionNo,
  schoolCode
) {
  return axios
    .put(WITHDRAW_URL, { registrationRecordNo, transactionNo, schoolCode })
    .catch((error) => {
      if (error.response.data.status === 409) {
        store.dispatch('setAPIError', {
          error,
          fallbackCode: API_ERROR_SUBTYPES.declineCertificationConflict
        })
      } else {
        store.dispatch('setAPIError', {
          error,
          fallbackCode: API_ERROR_SUBTYPES.declineCertification
        })
      }
      throw error
    })
}

function updateCertification(certificationJson, apicode) {
  store.commit('resetError')
  if (apicode === undefined) {
    apicode = API_ERROR_SUBTYPES.saveCertificationDetails
  }
  return axios.put(CERTIFICATIONS_URL, certificationJson).catch((error) => {
    if (error.response.data.status === 409) {
      store.dispatch('setAPIError', {
        error,
        fallbackCode: API_ERROR_SUBTYPES.saveCertificationConflict
      })
    } else {
      store.dispatch('setAPIError', { error, fallbackCode: apicode.toString() })
    }
    throw error
  })
}

export default {
  fetchCertifications,
  fetchCertificationById,
  updateCertification,
  certifyCertification,
  withdrawCertification
}
