<template>
  <v-checkbox
    class="checkbox"
    v-bind="attrs"
    v-on="$listeners"
    :aria-describedby="id"
    @change="$emit('input', $event)"
  >
    <template #prepend="">
      <legend :id="id" class="checkbox__legend v-label theme--light">
        {{ legend }}
      </legend>
    </template>
    <template #message="{ message }">
      <InputErrorMessage :message="message" />
    </template>
  </v-checkbox>
</template>

<script>
import { RADIO_OPTIONS } from '@/constants'
import InputErrorMessage from '@/components/form/InputErrorMessage'

export default {
  name: 'FormCheckbox',
  inheritAttrs: false,
  model: {
    prop: 'inputValue',
    event: 'change'
  },
  components: { InputErrorMessage },
  props: {
    id: {
      type: String,
      default: ''
    },
    legend: {
      type: String,
      default: ''
    },
    yesNoValues: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    attrs() {
      const defaultProps = {
        'persistent-hint': true
      }
      const yesNo = this.yesNoValues
        ? {
            trueValue: RADIO_OPTIONS.YES.value,
            falseValue: RADIO_OPTIONS.NO.value
          }
        : {}
      return { ...defaultProps, ...yesNo, ...this.$attrs }
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox {
  flex-direction: column;

  &.error--text .checkbox__legend {
    color: $ads-error-red;
  }

  &__legend {
    margin-bottom: 0.5rem;
    animation: none !important;
  }
}
</style>
