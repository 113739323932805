import tokenHelper from '@/utils/token'
import { USER_ROLE, USER_ACCESS_TYPE } from '@/constants'
import { getSchoolCodes } from '@/utils/general'
const userModule = {
  state: {
    user: {},
    schoolsForUser: []
  },
  getters: {
    user(state) {
      return state.user
    },
    schoolsForUser(state, getters) {
      let allSchools = getters.schools
      let userSchools = []
      if (getters.isOesSupport) {
        userSchools = allSchools
      } else if (getters.user?.acl?.length > 0) {
        let usersSchoolCodes = getSchoolCodes(getters.user)
        userSchools =
          usersSchoolCodes &&
          allSchools?.filter((s) => usersSchoolCodes.includes(s.schoolCode))
      }
      return userSchools
    },
    isOesSupport(state, getters) {
      return getters.user?.acl?.includes(USER_ACCESS_TYPE.OES_SUPPORT)
    },
    isPrincipal(state, getters) {
      const schoolOfSelctedCertification =
        getters.selectedCertification.schoolCode
      const hasPrincipalRole = getters.user?.roles?.includes(
        USER_ROLE.PRINCIPAL
      )
      const locations = getters.user?.locations
      if (Array.isArray(locations)) {
        return (
          hasPrincipalRole && locations.includes(schoolOfSelctedCertification)
        )
      } else if (typeof locations === 'string') {
        return hasPrincipalRole && locations === schoolOfSelctedCertification
      }
    },
    accessDeny(state, getters) {
      return getters.user?.acl?.length === 0
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    }
  },
  actions: {
    setCurrentUser({ commit }) {
      let decodedToken = tokenHelper.getToken('idToken')
      if (decodedToken) {
        commit('setUser', decodedToken)
      }
    }
  }
}

export default userModule
