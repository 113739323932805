<template>
  <ToggleButton
    class="sort-order-toggle"
    :value="isAscending"
    toggle-on-class="sort-order-toggle--ascending"
    toggle-off-class="sort-order-toggle--descending"
    :aria-label="ariaLabel"
    icon
    small
    @click="toggleValue"
  >
    <slot v-bind:="{ isAscending, isDescending }">
      <v-icon
        v-if="isAscending || isDescending"
        class="sort-order-toggle__icon"
      >
        mdi-arrow-up
      </v-icon>
    </slot>
  </ToggleButton>
</template>

<script>
import ToggleButton from '@/components/ToggleButton'

export default {
  name: 'SortOrderToggle',
  components: { ToggleButton },
  props: {
    value: {
      default: true,
      type: [Boolean, String]
    },
    ascendingValue: {
      default: false,
      type: [Boolean, String]
    },
    descendingValue: {
      default: true,
      type: [Boolean, String]
    }
  },
  data() {
    return {
      dataValue: this.value
    }
  },
  watch: {
    value(newValue) {
      this.dataValue = newValue
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.dataValue
      },
      set(newVal) {
        this.dataValue = newVal
        this.$emit('input', newVal)
      }
    },
    isDescending() {
      return this.internalValue === this.descendingValue
    },
    isAscending() {
      return this.internalValue === this.ascendingValue
    },
    ariaLabel() {
      return `Toggle sort order: ${
        this.isAscending ? 'ascending' : this.isDescending ? 'descending' : ''
      }`
    }
  },
  methods: {
    toggleValue() {
      if (this.internalValue === this.ascendingValue) {
        this.internalValue = this.descendingValue
      } else {
        this.internalValue = this.ascendingValue
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sort-order-toggle {
  transition: rotate 0.5s;
  border: 2px solid transparent;

  &:focus {
    border: 2px solid currentColor;
  }

  &::before {
    content: none !important;
  }
  &--descending {
    .sort-order-toggle__icon {
      transform: rotate(180deg);
    }
  }
}
</style>
