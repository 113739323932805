import {
  SCHOLASTIC_YEAR_KINDERGARTEN,
  CERTIFICATION_STATUS_ORDER
} from '@/constants'
import dayjs from 'dayjs'

export function sortBySchoolYear(yearA, yearB) {
  const yearToNum = (year) =>
    year === SCHOLASTIC_YEAR_KINDERGARTEN ? 0 : Number(year)
  return yearToNum(yearA) - yearToNum(yearB)
}

export function sortByStatus(statusA, statusB) {
  const statusToNum = (status) => {
    const order = CERTIFICATION_STATUS_ORDER.indexOf(status)
    return order >= 0 ? order : CERTIFICATION_STATUS_ORDER.length
  }
  return statusToNum(statusA) - statusToNum(statusB)
}

export function getSchoolCodes(user) {
  const pattern = / [0-9]{4} ERNADMIN$/
  if (Array.isArray(user.acl)) {
    return user.acl
      .filter((i) => pattern.test(i))
      .map((i) => {
        return i.split(' ').reverse()[1]
      })
  } else {
    if (pattern.test(user.acl)) return user.acl.split(' ').reverse()[1].split()
  }
}

export function displayDateFormat(date) {
  if (date) {
    return dayjs(date).format('DD MMMM YYYY')
  }
}

export function sanitiseStringForErn(str) {
  if (!str) {
    return str
  }

  const replacements = [
    // fancy single quotes
    {
      regExp: /[\u2018\u2019]/g,
      replace: "'"
    },
    // fancy double quotes
    {
      regExp: /[\u201C\u201D]/g,
      replace: '"'
    }
  ]
  return replacements.reduce((acc, r) => acc.replace(r.regExp, r.replace), str)
}

export function yesOrNoFromBooleanOrString(value) {
  if (typeof value === 'string' || value instanceof String) {
    const valueStr = value.trim().toLowerCase()
    if (valueStr === 'true' || valueStr === 'y') {
      return 'Yes'
    } else if (valueStr === 'false' || valueStr === 'n') {
      return 'No'
    }
  } else if (typeof value === 'boolean') {
    if (value === true) {
      return 'Yes'
    } else if (value === false) {
      return 'No'
    }
  }
  return ''
}

export function isValueNotNull(value) {
  return value && value.length > 0
}

export function formatPhoneNumber(val) {
  if (val) {
    // alternative Australia-wide landline number, eg: 13 00 00
    if (val.replace(/\s/g, '').length === 6) {
      val = val.replace(/\W/gi, '').replace(/(.{2})(.{2})/g, '$1 $2 ')
    }

    // landline number, eg: 1234 4321
    if (val.replace(/\s/g, '').length === 8) {
      val = val
        .replace(/\W/gi, '')
        .replace(/(.{4})/g, '$1 ')
        .trim()
    }

    if (val.replace(/\s/g, '').length === 10) {
      // for mobile and Australia-wide landline number. eg: 0400 000 000 || 1300 975 707
      if (
        val.replace(/\W/gi, '').substring(0, 2) === '04' ||
        val.replace(/\W/gi, '').substring(0, 2) === '05' ||
        val.replace(/\W/gi, '').substring(0, 2) === '13' ||
        val.replace(/\W/gi, '').substring(0, 2) === '18'
      ) {
        val = val.replace(/\W/gi, '').replace(/(.{4})(.{3})/g, '$1 $2 ')
      }
      // for landline number including area code, eg: 02 1234 4321
      if (
        val.replace(/\W/gi, '').substring(0, 2) === '02' ||
        val.replace(/\W/gi, '').substring(0, 2) === '03' ||
        val.replace(/\W/gi, '').substring(0, 2) === '07' ||
        val.replace(/\W/gi, '').substring(0, 2) === '08'
      ) {
        val = val.replace(/\W/gi, '').replace(/(.{2})(.{4})/g, '$1 $2 ')
      }
    }
  }
  return val
}
