<!-- notifications -->
<template>
  <div>
    <v-snackbar v-for="i in items" :key="i.id.toString()" v-bind="i.attr">
      <div class="d-flex justify-content align-center">
        <v-avatar :color="i.iconColor" class="mr-4" size="30">
          <v-icon color="white" size="20">
            {{ i.icon }}
          </v-icon>
        </v-avatar>
        <span class="black--text" v-html="i.message" />
      </div>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: 'AppNotifications',
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>
