import Vue from 'vue'
import Vuex, { Store } from 'vuex'

import certificationsModule from './modules/certifications'
import schoolsModule from './modules/schools'
import userModule from './modules/user'
import authModule from './modules/auth'
import errorModule from './modules/error'
import notificationsModule from './modules/notifications'
import settingsModule from './modules/settings'

Vue.use(Vuex)

export const config = {
  modules: {
    certifications: certificationsModule,
    schools: schoolsModule,
    user: userModule,
    auth: authModule,
    error: errorModule,
    notifications: notificationsModule,
    settings: settingsModule
  },
  state: () => ({
    isLoading: false,
    //this counter is to avoid race conditions by asynchronous API calls, isLoading will be true if loadingCounter>0
    loadingCounter: 0
  }),
  getters: {
    isLoading: (state) => state.isLoading
  },
  mutations: {
    setLoading: function (state, isLoading) {
      isLoading ? state.loadingCounter++ : state.loadingCounter--
      state.isLoading = !!state.loadingCounter
    }
  },
  actions: {}
}

export default new Store(config)
