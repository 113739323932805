<!-- Component for filter options (Checkbox and radio selection controls) -->
<template>
  <v-menu v-bind="attrs" v-on="$listeners">
    <template #activator="{ on }">
      <v-btn
        class="chip"
        :disabled="!filterOptions.length"
        :class="hasSelection ? 'selected' : ''"
        outlined
        rounded
        v-on="on"
        :id="ariaLabel"
        :aria-label="ariaLabel"
      >
        {{ label }}
        <v-icon>arrow_drop_down</v-icon>
      </v-btn>
    </template>
    <FocusTrap v-if="filterOptionsInternal.length" :is-active="value">
      <v-card class="filter-card">
        <v-list>
          <v-list-item>
            <v-list-item-title
              ><strong>Show only results for</strong></v-list-item-title
            >
            <v-list-item-action>
              <v-btn
                @click="$emit('input', false)"
                class="dialogCloseBtn"
                icon
                small
                aria-label="Close filter options menu"
              >
                <v-icon class="text-right closeBtn"> mdi-close </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <div v-if="type === 'checkbox'" class="filter-options">
            <v-list-item
              v-for="filter in filterOptionsInternal"
              :key="filter.value"
            >
              <OesCheckbox
                v-model="internalSelection"
                :label="filter.label"
                :value="filter.value"
                class="checkFilter"
              />
            </v-list-item>
          </div>
          <div v-if="type === 'radio'">
            <v-list-item>
              <v-radio-group v-model="internalSelection" class="filter-options">
                <v-radio
                  v-for="filter in filterOptionsInternal"
                  :key="filter.value"
                  :label="filter.label"
                  :value="filter.value"
                />
              </v-radio-group>
            </v-list-item>
            <div class="pl-4 pb-4 clearfilter">
              <a href="#" @click="clearFilter"> Clear filter </a>
            </div>
          </div>
        </v-list>
      </v-card>
    </FocusTrap>
  </v-menu>
</template>

<script>
import { FocusTrap } from '@nswdoe/doe-ui-core'
import OesCheckbox from './OesCheckbox.vue'

export default {
  name: 'ListViewFilter',
  props: {
    filterOptions: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    ariaLabel: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'checkbox'
    },
    selection: {
      type: Array,
      required: false
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FocusTrap,
    OesCheckbox
  },
  data() {
    return {
      menu: false,
      dataSelection: this.convertValueForCheckbox(this.selection)
    }
  },
  computed: {
    internalSelection: {
      get() {
        return this.dataSelection
      },
      set(newVal) {
        this.dataSelection = newVal
        this.$emit('update:selection', newVal)
      }
    },
    attrs() {
      const modalProps = {
        'content-class': 'filter-menu modal',
        transition: 'slide-y-transition',
        origin: 'top'
      }
      const defaultProps = {
        'content-class': 'filter-menu',
        bottom: true,
        right: true,
        transition: 'scale-transition',
        origin: 'top left',
        'close-on-content-click': false,
        value: this.value
      }
      return {
        ...defaultProps,
        ...(this.$vuetify.breakpoint.xsOnly && modalProps),
        ...this.$attrs
      }
    },
    hasSelection() {
      if (this.type === 'checkbox') {
        return !!this.internalSelection?.length
      }
      if (this.type === 'radio') {
        return this.internalSelection
      }
      return false
    },
    filterOptionsInternal() {
      return this.filterOptions.map((option) =>
        typeof option === 'object' ? option : { value: option, label: option }
      )
    }
  },
  watch: {
    selection(newValue) {
      this.dataSelection = this.convertValueForCheckbox(newValue)
    }
  },
  methods: {
    //Checkbox behaves oddly if an array isn't provided to v-model
    convertValueForCheckbox(value) {
      return this.type === 'checkbox' ? value || [] : value
    },
    clearFilter() {
      this.internalSelection = null
      this.$emit('update:selection', this.internalSelection)
    }
  }
}
</script>

<style scoped lang="scss">
.v-btn.chip.v-btn--outlined {
  border-color: $grey-darken1;
  border-width: 1px;
  background-color: white !important;
  color: $grey-darken1;
  height: 32px;
  font-size: 0.875rem;
  font-weight: normal;
  padding: 0 12px;
}
.v-btn.chip.v-btn--outlined.selected {
  background-color: $color-selected-item !important;
  color: $color-primary;
}
.checkFilter {
  margin-top: 2px;
}
a {
  text-decoration: none;
}

::v-deep .filter-card .theme--light.v-label {
  color: $grey-dark;
}

::v-deep .v-list-item__title {
  font-size: 16px;
}
.closeBtn {
  color: $color-primary;
}
.clearfilter {
  font-size: 14px;
  font-weight: bold;
  a {
    text-decoration: underline;
  }
}

.filter-card {
  width: 18rem;
}

.modal {
  &.filter-menu {
    width: 100%;
    max-width: 100%;
    border-radius: 0 !important;
    top: 0 !important;
    left: 0 !important;
  }

  .filter-card {
    width: 100%;
    padding: 0 0.5rem;
  }

  .filter-options,
  .filter-options ::v-deep .v-input--radio-group__input {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-height: 350px;

    > * {
      flex: 1 1 50px;
    }
  }
}
</style>
