import schoolsAPI from 'api/schools'

const schoolsModule = {
  state: {
    schools: [],
    currentSchool: {}
  },
  getters: {
    schools: (state) => {
      return state.schools
    },
    currentSchool: (state) => {
      return state.currentSchool
    }
  },
  mutations: {
    setSchools: function (state, schools) {
      state.schools = schools
    },
    setCurrentSchool: function (state, schoolCode) {
      let currentSchool = state.schools.find(
        (sch) => sch.schoolCode === schoolCode
      )
      state.currentSchool = currentSchool
    }
  },
  actions: {
    fetchSchools({ commit }) {
      if (!this.getters.isLoading) {
        commit('setLoading', true)
      }

      return schoolsAPI
        .fetchSchools()
        .then((response) => {
          commit('setSchools', response.data)
        })
        .finally(() => {
          if (this.getters.isLoading) {
            commit('setLoading', false)
          }
        })
    },
    setCurrentSchool: function ({ commit }, schoolCode) {
      commit('setCurrentSchool', schoolCode)
    }
  }
}

export default schoolsModule
