<template>
  <transition-group v-if="group" v-bind="attrs" v-on="listeners">
    <slot />
  </transition-group>
  <component :is="tag" aria-live="polite" aria-relevant="all" v-else>
    <transition v-bind="attrs" v-on="listeners">
      <slot />
    </transition>
  </component>
</template>

<script>
export default {
  name: 'TopSlideTransition',
  props: {
    group: {
      type: Boolean,
      default: false
    },
    duration: {
      type: Number,
      default: 300
    },
    tag: {
      type: String,
      default: 'div'
    },
    elementMaxHeight: {
      type: String,
      default: '10rem'
    }
  },
  computed: {
    listeners() {
      return {
        beforeEnter: this.setDuration,
        beforeLeave: this.setDuration,
        leave: this.setMaxHeight,
        enter: this.setMaxHeight,
        afterEnter: this.clearStyles,
        ...this.$listeners
      }
    },
    attrs() {
      const defaultAttrs = {
        name: 'slide'
      }
      const groupAttrs = {
        'aria-relevant': 'all',
        tag: this.tag
      }
      return { ...defaultAttrs, ...(this.group && groupAttrs), ...this.$attrs }
    }
  },
  methods: {
    setMaxHeight(el) {
      el.style.maxHeight = this.elementMaxHeight
    },
    setDuration(el) {
      el.style.transitionDuration = `${this.duration}ms`
    },
    clearStyles(el) {
      el.style.transitionDuration = null
      el.style.maxHeight = null
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep,
* {
  .slide-enter-active,
  .slide-leave-active,
  .slide-move {
    overflow-y: hidden;
    transition-property: all;
  }

  .slide-enter,
  .slide-leave-to {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    max-height: 0 !important;
  }
}
</style>
