import Vue from 'vue'
import VueRouter from 'vue-router'
import Certifications from '../views/Certifications.vue'
import Certification from '../views/Certification.vue'

Vue.use(VueRouter)

const queryParams = getQueryParams()
const isRedirectFromOpenAm = queryParams.code

const routes = [
  {
    path: '/',
    name: 'Certifications',
    component: Certifications
  },
  {
    path: '/certification',
    name: 'Certification',
    props: true,
    component: Certification
  },
  {
    path: '/logout',
    beforeEnter(to, from, next) {
      window.location.href = process.env.VUE_APP_AUTH_LOGOUT_URI

      // Remove the auth tokens in the next engine cycle rather than
      // immediately to prevent a race condition with `reauthenticateSession()`
      // in <AppSessionCountdownBar/>
      setTimeout(clearTokens, 0)
      // Make absolutely sure the router doesn't proceed from here.
      next(false)
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Before each route, check tokens and redirect to SSO if necessary...
router.beforeEach((to, from, next) => {
  if (
    !isTokens() &&
    !isCode() &&
    !isRedirectFromOpenAm &&
    to.path !== '/logout'
  ) {
    router.app.$auth.DET.startAuth()
    return
  }
  next()
})

export default router

// HELPER FUNCTIONS-------------------------------------------------------------

function isTokens() {
  // True if tokens present in session storage
  return !!(
    window.sessionStorage.getItem('accessToken') &&
    window.sessionStorage.getItem('idToken')
  )
}

function isCode() {
  // True if tokens present in session storage
  return !!window.sessionStorage.getItem('si.auth.code')
}

function getQueryParams() {
  return location.search
    .slice(1)
    .split('&')
    .map((p) => p.split('='))
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})
}

function clearTokens() {
  'accessToken,idToken,tokenExpiry,refreshToken'
    .split(',')
    .forEach((val) => sessionStorage.removeItem(val))
}
