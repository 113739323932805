<template>
  <v-container class="mt-6">
    <div v-if="parentCarers && parentCarers.length > 0">
      <div
        v-for="(parent, index) in parentCarers"
        :key="index"
        class="dataFields"
      >
        <v-row class="header mb-6">
          Parent/carer:
          {{
            parent.parentCarerGivenName
              ? parent.parentCarerGivenName
              : '(empty)'
          }}
          {{ parent.parentCarerFamilyName }}
        </v-row>
        <!-- ROW#1 -->
        <v-row>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="title(parent.parentCarerTitle)"
              label="Title"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="gender(parent.parentCarerGenderCode)"
              label="Gender"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="relationship(parent.parentCarerRelation)"
              label="Relationship to student"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
        </v-row>
        <!-- ROW#2 -->
        <v-row>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="parent.parentCarerFamilyName"
              label="Family name"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="parent.parentCarerGivenName"
              label="Given name"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="parent.parentCarerCountryBirth"
              label="Country of birth"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
        </v-row>
        <!-- ROW#3 -->
        <v-row>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="aboriginality(parent.isParentCarerAboriOrTorres)"
              label="Aboriginality"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
        </v-row>
        <!-- ROW#4 -->
        <div class="subHeader">Statement of account</div>
        <v-row>
          <v-col cols="12">
            <AdsTextField
              :value="statementOfAccountType(parent.statementOfAccount)"
              label="Statement of account"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
        </v-row>
        <!-- ROW#5 -->
        <div class="subHeader">Occupation</div>
        <v-row>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="parent.parentCarerOccupationName"
              label="Occupation"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="parent.parentCarerOccupationGroup"
              label="Occupation group"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
        </v-row>
        <!-- ROW#6 -->
        <div class="subHeader">Education</div>
        <v-row>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="parent.parentCarerSchoolEducationCode"
              label="Highest level of schooling completed"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="parent.parentCarerEducationQualificationCode"
              label="Highest qualification completed"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
        </v-row>
        <!-- ROW#7 -->
        <div class="subHeader">Languages other than English spoken at home</div>
        <v-row>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="yesOrNoWrapper(parent.isParentCarerSpeakLangOtherEnglish)"
              label="Does this parent/carer speak a language other than English at home?"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
            v-if="parent.isParentCarerSpeakLangOtherEnglish"
          >
            <AdsTextField
              :value="yesOrNoWrapper(parent.parentCarerRequireInterpreter)"
              label="Would an interpreter be required?"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
            v-if="parent.isParentCarerSpeakLangOtherEnglish"
          >
            <AdsTextField
              :value="parent.parentCarerMainLanguage"
              label="Main language other than English"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
        </v-row>
        <!-- ROW#8 -->
        <v-row v-if="parent.isParentCarerSpeakLangOtherEnglish">
          <v-col cols="12" md="4">
            <AdsTextField
              :value="parent.parentCarerSpeakLang"
              label="Other language spoken at home"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-else>
      <div class="subHeader mb-2">No family details available</div>
    </div>
  </v-container>
</template>

<script>
import { AdsTextField } from '@nswdoe/doe-ui-core'
import { mapGetters } from 'vuex'
import {
  TITLES,
  GENDER_CODES,
  RELATIONSHIPS,
  ABORIGINALITY_CODES,
  STATEMENT_OF_ACCOUNT_TYPES
} from '@/constants'

export default {
  name: 'ParentCarerDetails',
  components: { AdsTextField },
  computed: {
    ...mapGetters(['selectedErnStudentInfo']),
    parentCarers() {
      // isEnrolmentOwner is set TRUE that meaning that's for family details data
      const parentCarerContacts =
        this.selectedErnStudentInfo?.parentCarers.filter(
          (pc) => pc.isEnrolmentOwner
        )
      return parentCarerContacts ? parentCarerContacts : null
    }
  },
  methods: {
    title(value) {
      return TITLES.find((item) => item.value === value)?.text
    },
    gender(value) {
      return GENDER_CODES.find((item) => item.value === value)?.text
    },
    relationship(value) {
      return RELATIONSHIPS.find((item) => item.value === value)?.text
    },
    aboriginality(value) {
      return ABORIGINALITY_CODES.find((item) => item.code === value)?.value
    },
    statementOfAccountType(value) {
      return STATEMENT_OF_ACCOUNT_TYPES.find((item) => item.code === value)
        ?.value
    },
    yesOrNoWrapper(value) {
      if (value === true || value === 'true') {
        return 'Yes'
      }

      if (value === false || value === 'false') {
        return 'No'
      }

      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-input__slot fieldset {
  border: none;
}

::v-deep .col-md-4,
.col-12 {
  padding: 0 !important;
}
</style>
